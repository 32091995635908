import { takeLatest, put } from 'redux-saga/effects';

import {
  funcionarioCadastro,
  NEW_FUNCIONARIO_CADASTRO,
  NEW_FUNCIONARIO_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewFuncionarioCadastro(action) {

  yield put({ type: NEW_FUNCIONARIO_CADASTRO_SUCCESS, registro: funcionarioCadastro.newRegistro });
}

export default function* watchNewFuncionarioCadastro() {
  yield takeLatest(NEW_FUNCIONARIO_CADASTRO, sagaNewFuncionarioCadastro)
}
