import { takeLatest, put, call } from 'redux-saga/effects';

import { findFazendaCadastro } from '../service';
import {
  FIND_FAZENDA_CADASTRO,
  FIND_FAZENDA_CADASTRO_PENDING,
  FIND_FAZENDA_CADASTRO_SUCCESS,
  FIND_FAZENDA_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindFazendaCadastro(action) {
  yield put({ type: FIND_FAZENDA_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findFazendaCadastro, action.id);

    yield put({ type: FIND_FAZENDA_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_FAZENDA_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindFazendaCadastro() {
  yield takeLatest(FIND_FAZENDA_CADASTRO, sagaFindFazendaCadastro)
}
