import React, { Component } from "react";
import { Router } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import { connect } from "react-redux";
import { toast } from 'react-toastify';

// Import Routes
import Routes from './routes';
import { history } from './routes/history';

// Import scss
import "./assets/scss/theme.scss";

import 'react-toastify/dist/ReactToastify.css';

import 'primereact/resources/themes/saga-green/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

class App extends Component {
  constructor(props) {
    super(props);

    toast.configure();
  }

  render() {
    return (
      <Router history={history}>
        <HttpsRedirect>
          <Routes />
        </HttpsRedirect>
      </Router>
    );
  }
}

export default connect(null, null)(App);
