import { takeLatest, put, call } from 'redux-saga/effects';

import { listFazendaCadastro } from '../service';
import {
  TABLE_FAZENDA_CADASTRO,
  TABLE_FAZENDA_CADASTRO_PENDING,
  TABLE_FAZENDA_CADASTRO_SUCCESS,
  TABLE_FAZENDA_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListFazendaCadastro(action) {
  yield put({ type: TABLE_FAZENDA_CADASTRO_PENDING })

  try {
    const table = yield call(listFazendaCadastro, action.args)
    yield put({ type: TABLE_FAZENDA_CADASTRO_SUCCESS, table: table })

  } catch (error) {

    yield put({ type: TABLE_FAZENDA_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListFazendaCadastro() {
  yield takeLatest(TABLE_FAZENDA_CADASTRO, sagaListFazendaCadastro)
}