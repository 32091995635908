import { takeLatest, put, call } from 'redux-saga/effects';

import { listProdutoInsumoCadastro } from '../service';
import {
  TABLE_PRODUTO_INSUMO_CADASTRO,
  TABLE_PRODUTO_INSUMO_CADASTRO_PENDING,
  TABLE_PRODUTO_INSUMO_CADASTRO_SUCCESS,
  TABLE_PRODUTO_INSUMO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListProdutoInsumoCadastro(action) {
  yield put({ type: TABLE_PRODUTO_INSUMO_CADASTRO_PENDING })

  try {
    const table = yield call(listProdutoInsumoCadastro, action.args)
    yield put({ type: TABLE_PRODUTO_INSUMO_CADASTRO_SUCCESS, table: table })

  } catch (error) {

    yield put({ type: TABLE_PRODUTO_INSUMO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListProdutoInsumoCadastro() {
  yield takeLatest(TABLE_PRODUTO_INSUMO_CADASTRO, sagaListProdutoInsumoCadastro)
}