import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/procedimento' };
/////////////////////////////////////////////////////////////////////////////////

export function createProcedimentoCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateProcedimentoCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listProcedimentoCadastro(param) {
  return httpService.get({...args, args: param});
}

export function findProcedimentoCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function deleteProcedimentoCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

