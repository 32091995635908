import { takeLatest, put, call } from 'redux-saga/effects';

import { findSafraCadastro } from '../service';
import { listAllTalhaoAtivo } from '@handler/common/talhaoCadastro/service';
import { storage } from '@commons/storage';
import {
  CONFIG_SESSION,
  FIND_SAFRA_CADASTRO,
  FIND_SAFRA_CADASTRO_PENDING,
  FIND_SAFRA_CADASTRO_SUCCESS,
  FIND_SAFRA_CADASTRO_FAILURE
} from '@handler';

function* sagaFindSafraCadastro(action) {
  yield put({ type: FIND_SAFRA_CADASTRO_PENDING, id: action.id })

  try {

    const talhoesCadastro = yield call(listAllTalhaoAtivo, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id);

    var safraTalhaoList = new Map();
    talhoesCadastro.content.map(talhao => {
      return safraTalhaoList.set(talhao.id, {
        id: talhao.id,
        talhao: talhao,
        ativo: false
      });
    });

    const registro = yield call(findSafraCadastro, action.id);
    console.log(registro)
    let talhoes = []
    registro.safraTalhaoList.forEach((safra, key) => {
      safraTalhaoList.get(safra.talhao.id).ativo = true;
      talhoes = talhoes.concat(`${safra.talhao.id}`)
    })
    yield put({ type: FIND_SAFRA_CADASTRO_SUCCESS, registro: {
      ...registro,
      safraTalhaoList,
      talhoes
    } })

  } catch (error) {

    yield put({ type: FIND_SAFRA_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindSafraCadastro() {
  yield takeLatest(FIND_SAFRA_CADASTRO, sagaFindSafraCadastro)
}
