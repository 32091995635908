import { takeLatest, put, call } from 'redux-saga/effects';

import { history, storage } from '@commons';

import { createPlantioManejo, updatePlantioManejo } from '../service';
import {
  CONFIG_SESSION,
  CREATE_PLANTIO_MANEJO,
  CREATE_PLANTIO_MANEJO_PENDING,
  CREATE_PLANTIO_MANEJO_SUCCESS,
  CREATE_PLANTIO_MANEJO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'

import { formatJsonRemoveEmptyOrNull, isDateValidVerifyDateBetween } from '@components/common/format'

function* sagaCreatePlantioManejo(action) {
  yield put({ type: CREATE_PLANTIO_MANEJO_PENDING })

  try {
    const safraAtiva = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva;
    var dateFrom = safraAtiva.dataInicial;
    var dateTo = safraAtiva.dataFinal;
    var dateCheck = action.data.data;

    if (!isDateValidVerifyDateBetween(dateFrom, dateTo, dateCheck)) {
      yield put({ type: CREATE_PLANTIO_MANEJO_FAILURE, registro: action.data })

      AlertWarning('Data informada fora do período da safra ativa. Verifique...');
    } else {
      if (action.data.id === undefined || action.data.id === null) {
        yield call(createPlantioManejo, formatJsonRemoveEmptyOrNull({
          ...action.data,
          'forJson': 'AtivPlantio',
          'sync': false,
          propriedade: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
          },
          safra: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.id
          }
        }));
      } else {
        yield call(updatePlantioManejo,
          {
            ...action.data,
            id: action.data.id
          }
        );
      }

      yield put({ type: CREATE_PLANTIO_MANEJO_SUCCESS, registro: action.data })

      history.push('/plantioManejo');

      AlertSuccess('Registro salvo com sucesso!');
    }
  } catch (error) {
    yield put({ type: CREATE_PLANTIO_MANEJO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }
}

export default function* watchCreatePlantioManejo() {
  yield takeLatest(CREATE_PLANTIO_MANEJO, sagaCreatePlantioManejo)
}
