import { all } from 'redux-saga/effects';

import { loginSaga, loginReducer } from './login';

import { configAgriculturaSaga, configAgriculturaReducer } from './config/agricultura';

import { startSessionSaga, startSessionReducer } from './common/startSession';
import { fazendaCadastroSaga, fazendaCadastroReducer } from './common/fazendaCadastro';
import { talhaoCadastroSaga, talhaoCadastroReducer } from './common/talhaoCadastro';
import { funcionarioCargoCadastroSaga, funcionarioCargoCadastroReducer } from './common/funcionarioCargoCadastro';
import { funcionarioCadastroSaga, funcionarioCadastroReducer } from './common/funcionarioCadastro';
import { tipoEmbalagemCadastroSaga, tipoEmbalagemCadastroReducer } from './common/tipoEmbalagemCadastro';
import { patrimonioCadastroSaga, patrimonioCadastroReducer } from './common/patrimonioCadastro';
import { produtoInsumoCadastroSaga, produtoInsumoCadastroReducer } from './common/produtoInsumoCadastro';

import { dashboardSaga, dashboardReducer } from './agricultura/dashboard';
import { safraCadastroSaga, safraCadastroReducer } from './agricultura/safraCadastro';
import { procedimentoCadastroSaga, procedimentoCadastroReducer } from './agricultura/procedimentoCadastro';
import { despesaDiretaCadastroSaga, despesaDiretaCadastroReducer } from './agricultura/despesaDiretaCadastro';
import { plantioManejoSaga, plantioManejoReducer } from './agricultura/atividadeAgricola/plantioManejo';
import { colheitaSaga, colheitaReducer } from './agricultura/atividadeAgricola/colheita';

export default function* rootSaga() {
  yield all([
    ...loginSaga,
    ...startSessionSaga,
    ...configAgriculturaSaga,
    ...fazendaCadastroSaga,
    ...talhaoCadastroSaga,
    ...funcionarioCargoCadastroSaga,
    ...funcionarioCadastroSaga,
    ...tipoEmbalagemCadastroSaga,
    ...patrimonioCadastroSaga,
    ...produtoInsumoCadastroSaga,
    ...safraCadastroSaga,
    ...procedimentoCadastroSaga,
    ...despesaDiretaCadastroSaga,
    ...plantioManejoSaga,
    ...colheitaSaga,
    ...dashboardSaga,
  ])
}

export const rootReducer = {
  loginReducer,
  startSessionReducer,
  configAgriculturaReducer,
  fazendaCadastroReducer,
  talhaoCadastroReducer,
  funcionarioCargoCadastroReducer,
  funcionarioCadastroReducer,
  tipoEmbalagemCadastroReducer,
  patrimonioCadastroReducer,
  produtoInsumoCadastroReducer,
  safraCadastroReducer,
  procedimentoCadastroReducer,
  despesaDiretaCadastroReducer,
  plantioManejoReducer,
  colheitaReducer,
  dashboardReducer,
}

export * from './login';
export * from './common/startSession';
export * from './config/agricultura';
export * from './common/fazendaCadastro';
export * from './common/talhaoCadastro';
export * from './common/funcionarioCargoCadastro';
export * from './common/funcionarioCadastro';
export * from './common/tipoEmbalagemCadastro';
export * from './common/patrimonioCadastro';
export * from './common/produtoInsumoCadastro';
export * from './agricultura/dashboard';
export * from './agricultura/safraCadastro';
export * from './agricultura/procedimentoCadastro';
export * from './agricultura/despesaDiretaCadastro';
export * from './agricultura/atividadeAgricola/plantioManejo';
export * from './agricultura/atividadeAgricola/colheita';
