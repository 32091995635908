import { takeLatest, put } from 'redux-saga/effects';

import {
  tipoEmbalagemCadastro,
  NEW_TIPO_EMBALAGEM_CADASTRO,
  NEW_TIPO_EMBALAGEM_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewTipoEmbalagemCadastro(action) {

  yield put({ type: NEW_TIPO_EMBALAGEM_CADASTRO_SUCCESS, registro: tipoEmbalagemCadastro.newRegistro });
}

export default function* watchNewTipoEmbalagemCadastro() {
  yield takeLatest(NEW_TIPO_EMBALAGEM_CADASTRO, sagaNewTipoEmbalagemCadastro)
}
