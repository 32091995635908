


import watchNewTalhaoCadastro from './saga/new';
import watchFindTalhaoCadastro from './saga/find';
import watchCreateTalhaoCadastro from './saga/create';
import watchListTalhaoCadastro from './saga/listTable';
import watchDeleteTalhaoCadastro from './saga/delete';

export const talhaoCadastroSaga = [
    watchNewTalhaoCadastro(),
    watchFindTalhaoCadastro(),
    watchCreateTalhaoCadastro(),
    watchListTalhaoCadastro(),
    watchDeleteTalhaoCadastro(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
