import React from 'react';

import {
    Button,
} from 'reactstrap';

// Sweet Alert
import swal from 'sweetalert';

class Delete extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            option: {
                title: 'Atenção!',
                // indexFieldMsgDelete = -1 -> geral (pode usar props msgDelete para setar msg especifica)
                // indexFieldMsgDelete = 0 -> registro.nome
                // indexFieldMsgDelete = 1 -> registro.descricao
                text: this.props.indexFieldMsgDelete === -1 ?
                (this.props.msgDelete === undefined ? 'Confirma a exclusão do item selecionado?' : this.props.msgDelete)
                : ('Deseja excluir o item: ' + (this.props.indexFieldMsgDelete === 0 ? this.props.registro.nome : this.props.registro.descricao)),
                icon: 'warning',
                buttons: {
                    cancel: true,
                    confirm: {
                        text: 'Sim',
                        value: true,
                        visible: true,
                        className: 'bg-danger',
                        closeModal: true
                    }
                }
            }
        }
    }

    handleClick = (e) => {
        swal(this.state.option).then(
            (confirm) => {
                if (confirm) {
                    this.props.remove(this.props.registro);
                }
            }
        );
    }

    render() {


        return (
            <>

                <Button outline color='white' className='btn btn-danger btn-sm' onClick={this.handleClick}>
                    <i className='fas fa-trash' />
                </Button>

            </>
        );
    }

}

export { Delete };