export const PATRIMONIO_TIPO_MAQUINA_ENUM = [
        {
            id: 'ADUBADOR',
            descricao: "Adubador",
        },
        {
            id: 'ARADO',
            descricao: "Arado",
        },
        {
            id: 'COLHEITADEIRA',
            descricao: "Colheitadeira",
        },
        {
            id: 'PULVERIZADOR',
            descricao: "Pulverizador",
        },
        {
            id: 'SEMEADEIRA',
            descricao: "Semeadeira",
        },
        {
            id: 'TRATOR',
            descricao: "Trator",
        },
        {
            id: 'OUTRO',
            descricao: "Outro",
        },
    ];