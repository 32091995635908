
export const NEW_DESPESA_DIRETA_CADASTRO = 'NEW_DESPESA_DIRETA_CADASTRO'
export const NEW_DESPESA_DIRETA_CADASTRO_SUCCESS = 'NEW_DESPESA_DIRETA_CADASTRO_SUCCESS'

export const CREATE_DESPESA_DIRETA_CADASTRO = 'CREATE_DESPESA_DIRETA_CADASTRO'
export const CREATE_DESPESA_DIRETA_CADASTRO_PENDING = 'CREATE_DESPESA_DIRETA_CADASTRO_PENDING'
export const CREATE_DESPESA_DIRETA_CADASTRO_SUCCESS = 'CREATE_DESPESA_DIRETA_CADASTRO_SUCCESS'
export const CREATE_DESPESA_DIRETA_CADASTRO_FAILURE = 'CREATE_DESPESA_DIRETA_CADASTRO_FAILURE'

export const TABLE_DESPESA_DIRETA_CADASTRO = 'TABLE_DESPESA_DIRETA_CADASTRO'
export const TABLE_DESPESA_DIRETA_CADASTRO_PENDING = 'TABLE_DESPESA_DIRETA_CADASTRO_PENDING'
export const TABLE_DESPESA_DIRETA_CADASTRO_SUCCESS = 'TABLE_DESPESA_DIRETA_CADASTRO_SUCCESS'
export const TABLE_DESPESA_DIRETA_CADASTRO_FAILURE = 'TABLE_DESPESA_DIRETA_CADASTRO_FAILURE'

export const LIST_DESPESA_DIRETA_CADASTRO = 'LIST_DESPESA_DIRETA_CADASTRO'
export const LIST_DESPESA_DIRETA_CADASTRO_PENDING = 'LIST_DESPESA_DIRETA_CADASTRO_PENDING'
export const LIST_DESPESA_DIRETA_CADASTRO_SUCCESS = 'LIST_DESPESA_DIRETA_CADASTRO_SUCCESS'
export const LIST_DESPESA_DIRETA_CADASTRO_FAILURE = 'LIST_DESPESA_DIRETA_CADASTRO_FAILURE'

export const FIND_DESPESA_DIRETA_CADASTRO = 'FIND_DESPESA_DIRETA_CADASTRO'
export const FIND_DESPESA_DIRETA_CADASTRO_PENDING = 'FIND_DESPESA_DIRETA_CADASTRO_PENDING'
export const FIND_DESPESA_DIRETA_CADASTRO_SUCCESS = 'FIND_DESPESA_DIRETA_CADASTRO_SUCCESS'
export const FIND_DESPESA_DIRETA_CADASTRO_FAILURE = 'FIND_DESPESA_DIRETA_CADASTRO_FAILURE'

export const DELETE_DESPESA_DIRETA_CADASTRO = 'DELETE_DESPESA_DIRETA_CADASTRO'
export const DELETE_DESPESA_DIRETA_CADASTRO_PENDING = 'DELETE_DESPESA_DIRETA_CADASTRO_PENDING'
export const DELETE_DESPESA_DIRETA_CADASTRO_SUCCESS = 'DELETE_DESPESA_DIRETA_CADASTRO_SUCCESS'
export const DELETE_DESPESA_DIRETA_CADASTRO_FAILURE = 'DELETE_DESPESA_DIRETA_CADASTRO_FAILURE'
