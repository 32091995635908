import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Redirect } from 'react-router-dom';

import HorizontalLayout from "../@components/layout/HorizontalLayout";

import { PrivateRoute } from '@components/common/PrivateRoute';
import { PublicRoute } from '@components/common/PublicRoute';

/* loader component for Suspense*/
import PageLoader from '@components/common/PageLoader';


// PUBLIC
const LoginPage = lazy(() => import('../pages/public/LoginPage/LoginPage'));


// COMMOM
const SelecionarFazenda = lazy(() => import('../pages/private/common/SelecionarFazenda'));
const Config = lazy(() => import('../pages/private/config'));


// TALHAO
const TalhaoCadastro = lazy(() => import('../pages/private/common/TalhaoCadastro'));
const NovoTalhao = lazy(() => import('../pages/private/common/TalhaoCadastro/cadastro/new'));
const EditarTalhao = lazy(() => import('../pages/private/common/TalhaoCadastro/cadastro/edit'));

// FUNCIONARIO
const FuncionarioCadastro = lazy(() => import('../pages/private/common/FuncionarioCadastro'));
const NovoFuncionario = lazy(() => import('../pages/private/common/FuncionarioCadastro/cadastro/new'));
const EditarFuncionario = lazy(() => import('../pages/private/common/FuncionarioCadastro/cadastro/edit'));

// FUNCIONARIO CARGO
const FuncionarioCargoCadastro = lazy(() => import('../pages/private/common/FuncionarioCargoCadastro'));
const NovoFuncionarioCargo = lazy(() => import('../pages/private/common/FuncionarioCargoCadastro/cadastro/new'));
const EditarFuncionarioCargo = lazy(() => import('../pages/private/common/FuncionarioCargoCadastro/cadastro/edit'));

// TIPO EMBALAGEM
const TipoEmbalagemCadastro = lazy(() => import('../pages/private/common/TipoEmbalagemCadastro'));
const NovoTipoEmbalagem = lazy(() => import('../pages/private/common/TipoEmbalagemCadastro/cadastro/new'));
const EditarTipoEmbalagem = lazy(() => import('../pages/private/common/TipoEmbalagemCadastro/cadastro/edit'));

// PATRIMONIO
const PatrimonioCadastro = lazy(() => import('../pages/private/common/PatrimonioCadastro'));
const NovoPatrimonio = lazy(() => import('../pages/private/common/PatrimonioCadastro/cadastro/new'));
const EditarPatrimonio = lazy(() => import('../pages/private/common/PatrimonioCadastro/cadastro/edit'));

// PRODUTO-INSUMO
const ProdutoInsumoCadastro = lazy(() => import('../pages/private/common/ProdutoInsumoCadastro'));
const NovoProdutoInsumo = lazy(() => import('../pages/private/common/ProdutoInsumoCadastro/cadastro/new'));
const EditarProdutoInsumo = lazy(() => import('../pages/private/common/ProdutoInsumoCadastro/cadastro/edit'));



// AGRICULTURA
const BreadcrumbAgricCadastros = lazy(() => import('../pages/private/agricultura/Breadcrumb/cadastros'));
const PainelAgricultura = lazy(() => import('../pages/private/agricultura/Dashboard'));

// SAFRA
const SafraCadastro = lazy(() => import('../pages/private/agricultura/SafraCadastro'));
const NovaSafra = lazy(() => import('../pages/private/agricultura/SafraCadastro/cadastro/new'));
const EditarSafra = lazy(() => import('../pages/private/agricultura/SafraCadastro/cadastro/edit'));

// PROCEDIMENTO
const ProcedimentoCadastro = lazy(() => import('../pages/private/agricultura/ProcedimentoCadastro'));
const NovoProcedimento = lazy(() => import('../pages/private/agricultura/ProcedimentoCadastro/cadastro/new'));
const EditarProcedimento = lazy(() => import('../pages/private/agricultura/ProcedimentoCadastro/cadastro/edit'));

// DESPESA DIRETA
const DespesaDiretaCadastro = lazy(() => import('../pages/private/agricultura/DespesaDiretaCadastro'));
const NovoDespesaDireta = lazy(() => import('../pages/private/agricultura/DespesaDiretaCadastro/cadastro/new'));
const EditarDespesaDireta = lazy(() => import('../pages/private/agricultura/DespesaDiretaCadastro/cadastro/edit'));

// ATIVIDADE PLANTIO E MANEJO
const PlantioManejo = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/PlantioManejo'));
const NovoPlantioManejo = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/PlantioManejo/cadastro/new'));
const EditarPlantioManejo = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/PlantioManejo/cadastro/edit'));
const NovoPlantioManejoCustoDireto = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/PlantioManejo/custoDireto/new'));
const EditarPlantioManejoCustoDireto = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/PlantioManejo/custoDireto/edit'));

// ATIVIDADE COLHEITA
const Colheita = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/Colheita'));
const NovaColheita = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/Colheita/cadastro/new'));
const EditarColheita = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/Colheita/cadastro/edit'));


const listofPages = [
    '/login'
];

const listPrivateNoThemofPages = [
    '/selecionarFazenda'
];

const Routes = ({ location }) => {
    if (listofPages.indexOf(location.pathname) > -1) {
        return (
            <Suspense fallback={<PageLoader />}>
                <Switch location={location}>
                    <PublicRoute path='/login' component={LoginPage} />
                    {/* <PublicRoute path='/recover' component={RecuperarSenhaPage} /> */}
                </Switch>
            </Suspense>
        )
    }
    else {
        if (listPrivateNoThemofPages.indexOf(location.pathname) > -1) {
            return (
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <PrivateRoute exact path='/selecionarFazenda' component={SelecionarFazenda} />
                    </Switch>
                </Suspense>
            )
        }
        else {
            return (
                <HorizontalLayout>
                    <Suspense fallback={<PageLoader />}>
                        <Switch location={location}>
                            <PrivateRoute exact path='/painel/agricultura' component={PainelAgricultura} />

                            <PrivateRoute exact path='/config' component={Config} />

                            <PrivateRoute exact path='/agricultura/cadastros' component={BreadcrumbAgricCadastros} />

                            <PrivateRoute exact path='/talhaoCadastro' component={TalhaoCadastro} />
                            <PrivateRoute exact path='/talhaoCadastro/novo' component={NovoTalhao} />
                            <PrivateRoute path='/talhaoCadastro/editar/:id' component={EditarTalhao} />

                            <PrivateRoute exact path='/funcionarioCadastro' component={FuncionarioCadastro} />
                            <PrivateRoute exact path='/funcionarioCadastro/novo' component={NovoFuncionario} />
                            <PrivateRoute path='/funcionarioCadastro/editar/:id' component={EditarFuncionario} />

                            <PrivateRoute exact path='/funcionarioCargoCadastro' component={FuncionarioCargoCadastro} />
                            <PrivateRoute exact path='/funcionarioCargoCadastro/novo' component={NovoFuncionarioCargo} />
                            <PrivateRoute path='/funcionarioCargoCadastro/editar/:id' component={EditarFuncionarioCargo} />

                            <PrivateRoute exact path='/patrimonioCadastro' component={PatrimonioCadastro} />
                            <PrivateRoute exact path='/patrimonioCadastro/novo' component={NovoPatrimonio} />
                            <PrivateRoute path='/patrimonioCadastro/editar/:id' component={EditarPatrimonio} />

                            <PrivateRoute exact path='/produtoInsumoCadastro' component={ProdutoInsumoCadastro} />
                            <PrivateRoute exact path='/produtoInsumoCadastro/novo' component={NovoProdutoInsumo} />
                            <PrivateRoute path='/produtoInsumoCadastro/editar/:id' component={EditarProdutoInsumo} />

                            <PrivateRoute exact path='/tipoEmbalagemCadastro' component={TipoEmbalagemCadastro} />
                            <PrivateRoute exact path='/tipoEmbalagemCadastro/novo' component={NovoTipoEmbalagem} />
                            <PrivateRoute path='/tipoEmbalagemCadastro/editar/:id' component={EditarTipoEmbalagem} />

                            <PrivateRoute exact path='/safraCadastro' component={SafraCadastro} />
                            <PrivateRoute exact path='/safraCadastro/novo' component={NovaSafra} />
                            <PrivateRoute path='/safraCadastro/editar/:id' component={EditarSafra} />

                            <PrivateRoute exact path='/procedimentoCadastro' component={ProcedimentoCadastro} />
                            <PrivateRoute exact path='/procedimentoCadastro/novo' component={NovoProcedimento} />
                            <PrivateRoute path='/procedimentoCadastro/editar/:id' component={EditarProcedimento} />

                            <PrivateRoute exact path='/despesaDiretaCadastro' component={DespesaDiretaCadastro} />
                            <PrivateRoute exact path='/despesaDiretaCadastro/novo' component={NovoDespesaDireta} />
                            <PrivateRoute path='/despesaDiretaCadastro/editar/:id' component={EditarDespesaDireta} />

                            <PrivateRoute exact path='/plantioManejo' component={PlantioManejo} />
                            <PrivateRoute exact path='/plantioManejo/novo' component={NovoPlantioManejo} />
                            <PrivateRoute path='/plantioManejo/editar/:id' component={EditarPlantioManejo} />
                            <PrivateRoute exact path='/plantioManejo/custoDireto/novo' component={NovoPlantioManejoCustoDireto} />
                            <PrivateRoute path='/plantioManejo/custoDireto/editar/:id' component={EditarPlantioManejoCustoDireto} />

                            <PrivateRoute exact path='/colheita' component={Colheita} />
                            <PrivateRoute exact path='/colheita/novo' component={NovaColheita} />
                            <PrivateRoute path='/colheita/editar/:id' component={EditarColheita} />

                            <Redirect to='/painel/agricultura' />
                        </Switch>
                    </Suspense>
                </HorizontalLayout>
            )
        }
    }
}

export default withRouter(Routes);
