import {
    NEW_SAFRA_CADASTRO_PENDING,
    NEW_SAFRA_CADASTRO_SUCCESS,
    NEW_SAFRA_CADASTRO_FAILURE,
    TABLE_SAFRA_CADASTRO_PENDING,
    TABLE_SAFRA_CADASTRO_SUCCESS,
    TABLE_SAFRA_CADASTRO_FAILURE,
    LIST_SAFRA_CADASTRO_PENDING,
    LIST_SAFRA_CADASTRO_SUCCESS,
    LIST_SAFRA_CADASTRO_FAILURE,
    FIND_SAFRA_CADASTRO_PENDING,
    FIND_SAFRA_CADASTRO_SUCCESS,
    FIND_SAFRA_CADASTRO_FAILURE,
    DELETE_SAFRA_CADASTRO_PENDING,
    DELETE_SAFRA_CADASTRO_SUCCESS,
    DELETE_SAFRA_CADASTRO_FAILURE,
    CREATE_SAFRA_CADASTRO_PENDING,
    CREATE_SAFRA_CADASTRO_SUCCESS,
    CREATE_SAFRA_CADASTRO_FAILURE,
} from './actionTypes';


const initialState = { list: null, loading: false }


function safraCadastroReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_SAFRA_CADASTRO_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case NEW_SAFRA_CADASTRO_PENDING:
        case LIST_SAFRA_CADASTRO_PENDING:
        case FIND_SAFRA_CADASTRO_PENDING:
        case DELETE_SAFRA_CADASTRO_PENDING:
        case CREATE_SAFRA_CADASTRO_PENDING:
        case TABLE_SAFRA_CADASTRO_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_SAFRA_CADASTRO_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_SAFRA_CADASTRO_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_SAFRA_CADASTRO_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_SAFRA_CADASTRO_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_SAFRA_CADASTRO_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_SAFRA_CADASTRO_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_SAFRA_CADASTRO_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_SAFRA_CADASTRO_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: null
            }
        case DELETE_SAFRA_CADASTRO_FAILURE:
        case NEW_SAFRA_CADASTRO_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case CREATE_SAFRA_CADASTRO_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { safraCadastroReducer };