import watchTotais from './saga/totais';
import watchGraficoCustoProducaoPorTalhao from './saga/graficoCustoProducaoPorTalhao';
import watchGraficoCustoTipoInsumo from './saga/graficoCustoTipoInsumo';
import watchListCustos from './saga/listCustos';

export const dashboardSaga = [
    watchTotais(),
    watchGraficoCustoProducaoPorTalhao(),
    watchGraficoCustoTipoInsumo(),
    watchListCustos(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
