import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk';
import ReduxThunk from 'redux-thunk';

import rootSaga, { rootReducer } from '@handler';
import Layout from "./layout/reducer";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
    applyMiddleware(
        ReduxThunk,
        thunkMiddleware,
        sagaMiddleware
    ));


const store = createStore(
    combineReducers({
        Layout,
        entities: combineReducers({
            ...rootReducer,
        })
    }),
    enhancer
);

sagaMiddleware.run(rootSaga);

export default store;