import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import { getGraficoCustoTipoInsumo } from '../service';
import {
    CONFIG_SESSION,
    GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD,
    GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_PENDING,
    GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_SUCCESS,
    GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_FAILURE
} from '@handler';
import { formatDateDDMMYYYY } from '@components/common/format';

function* sagaGraficoCustoTipoInsumo(action) {
    yield put({ type: GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_PENDING })

    try {
        const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada === null ? -1 : JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
        const idSafra = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva === null ? -1 : JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.id;

        const dadosGraficoCustoTipoInsumo = yield call(getGraficoCustoTipoInsumo,
            idFazenda,
            idSafra,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        let chartData = [['Grupo', 'Total']];
        for (let i = 0; i < dadosGraficoCustoTipoInsumo.length; i += 1) {
            chartData.push([dadosGraficoCustoTipoInsumo[i].text, dadosGraficoCustoTipoInsumo[i].value])
        }

        yield put({ type: GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_SUCCESS, dadosGraficoCustoTipoInsumo: chartData })

    } catch (error) {
        yield put({ type: GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_FAILURE })

    }
}

export default function* watchGraficoCustoTipoInsumo() {
    yield takeLatest(GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD, sagaGraficoCustoTipoInsumo)
}
