import { takeLatest, put } from 'redux-saga/effects';

import {
  fazendaCadastro,
  NEW_FAZENDA_CADASTRO,
  NEW_FAZENDA_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewFazendaCadastro(action) {

  yield put({ type: NEW_FAZENDA_CADASTRO_SUCCESS, registro: fazendaCadastro.newRegistro });
}

export default function* watchNewFazendaCadastro() {
  yield takeLatest(NEW_FAZENDA_CADASTRO, sagaNewFazendaCadastro)
}
