import React from 'react';

import {
    Col,
    FormGroup,
    Label,
    FormFeedback,
    Input,
    InputGroup
} from 'reactstrap';

import { Field } from 'formik';

export const InputTextArea = (props) => {

    const { col, name, label, placeholder, plusChange, rows } = props;

    return (
        <Col md={col}>

            <FormGroup>

                <Label htmlFor={'id_' + name} >{label}</Label>
                <Field name={name}>
                    {({ field, form, meta }) => (
                        <>
                            <InputGroup>
                                <Input
                                    {...field}
                                    name={name}
                                    value={field.value ?? ''}
                                    as='textarea'
                                    type={props.type}
                                    placeholder={placeholder}
                                    id={'id_' + name}
                                    invalid={meta.error && meta.touched}
                                    className='form-control'
                                    autoComplete="off"
                                    rows={rows}
                                    onChange={(e) => {
                                        form.handleChange(e);
                                        plusChange(e);
                                    }}
                                />
                                <FormFeedback>{meta.error}</FormFeedback>
                            </InputGroup>
                        </>
                    )}
                </Field>
            </FormGroup>

        </Col>
    )

};

InputTextArea.defaultProps = {
    col: 12,
    rows: 10,
    type: 'textarea',
    plusChange: () => { }
};