import { takeLatest, put } from 'redux-saga/effects';

import {
  talhaoCadastro,
  NEW_TALHAO_CADASTRO,
  NEW_TALHAO_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewTalhaoCadastro(action) {

  yield put({ type: NEW_TALHAO_CADASTRO_SUCCESS, registro: talhaoCadastro.newRegistro });
}

export default function* watchNewTalhaoCadastro() {
  yield takeLatest(NEW_TALHAO_CADASTRO, sagaNewTalhaoCadastro)
}
