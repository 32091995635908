import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/config/agricultura' };
/////////////////////////////////////////////////////////////////////////////////

export function updateConfigAgricultura(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function findConfigAgricultura(id) {
  return httpService.get({ ...args, args: `/${id}` });
}
