import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    status: true,
    marca: '',
    modelo: '',
    tipo: null,
    valor: '',
    dataAquisicao: '',
    numeroSerie: '',
    vidaUtilHora: '',
    obs: '',
    tipoMaquina: null,
    horimetroQuilometragemAtual: 0,
    custoHoraQuilometro: 0,
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(100, 'Campo deve ter menos do que 255 caracteres')
        .required('Este campo é obrigatório'),
    marca: Yup.string()
        .max(100, 'Campo deve ter menos do que 100 caracteres'),
    modelo: Yup.string()
        .max(100, 'Campo deve ter menos do que 100 caracteres'),
    tipo: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    valor: Yup.number()
        .transform(value => (isNaN(value) ? 0.0 : value)),
    numeroSerie: Yup.string()
        .max(200, 'Campo deve ter menos do que 200 caracteres'),
    vidaUtilHora: Yup.number()
        .integer('Por Favor, informe apenas a Hora cheia'),
    obs: Yup.string()
        .max(2000, 'Campo deve ter menos do que 2000 caracteres'),
    horimetroQuilometragemAtual: Yup.number()
        .transform(value => (isNaN(value) ? 0.0 : value)),
    custoHoraQuilometro: Yup.number()
        .transform(value => (isNaN(value) ? 0.0 : value)),
    // CAMPO TIPO MAQUINA
    tipoMaquina: Yup.string().when('tipo', {
        is: (tipo) => (
            tipo === 'MAQUINA'),
        then: Yup.string()
            .typeError('Este campo é obrigatório')
            .required('Este campo é obrigatório'),
    }),
});

export const patrimonioCadastro = {
    newRegistro,
    validation
}