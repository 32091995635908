import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import { deleteColheita } from '../service';
import {
  DELETE_COLHEITA,
  DELETE_COLHEITA_PENDING,
  DELETE_COLHEITA_SUCCESS,
  DELETE_COLHEITA_FAILURE,
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaDeleteColheita(action) {
  yield put({ type: DELETE_COLHEITA_PENDING, id: action.id })

  try {
    const a = yield call(deleteColheita, action.id);

    if (a.status !== 204) {
      throw new Error('API delete request failed');
    }
    yield put({ type: DELETE_COLHEITA_SUCCESS, id: action.id });

    swal('Excluído!', '', 'success').then(() => {
      history.go(0)
    });

  } catch (error) {

    yield put({ type: DELETE_COLHEITA_FAILURE });

    AlertError('Falha ao exlcuir o registro! Tente novamente...');
  }
}

export default function* watchDeleteColheita() {
  yield takeLatest(DELETE_COLHEITA, sagaDeleteColheita)
}
