import { takeLatest, put, call } from 'redux-saga/effects';

import { history, storage } from '@commons';

import {
  CONFIG_SESSION,
  CREATE_START_SESSION,
  CREATE_START_SESSION_PENDING,
  CREATE_START_SESSION_SUCCESS,
  CREATE_START_SESSION_FAILURE
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

import { findAllFazendaCadastro, findFazendaCadastro } from '@handler/common/fazendaCadastro/service';
import { findAllSafrasByPropriedade } from '@handler/agricultura/safraCadastro/service';

import { httpAuthService } from '@commons';
import { path } from '@commons/path';

function* sagaCreateStartSession(action) {
  yield put({ type: CREATE_START_SESSION_PENDING })

  try {
    // get licença
    const fazendas = yield call(findAllFazendaCadastro);
    
    let configSession = null;
    if (fazendas.length === 1) {
      const fazendaSelecionada = yield call(findFazendaCadastro, fazendas[0].id);

      const safras = yield call(findAllSafrasByPropriedade, fazendaSelecionada.id);
    
      let safraAtiva = null;

      if (safras.content.length === 1) {
        safraAtiva = safras.content[0]
      }

      //////////////////////////////////////////////////////////////////////
      // config session start deve ser atualizada nesse arquivo para qndo possuir uma unica findAllSafrasByPropriedade, e no arquivo ./select que é executado para +1 propriedade
      //////////////////////////////////////////////////////////////////////
      configSession = {
        fazendas,
        fazendaSelecionada,
        safras: safras.content,
        safraAtiva
        // ...licenca
      }

      yield storage.set(CONFIG_SESSION, JSON.stringify(configSession));

      yield put({ type: CREATE_START_SESSION_SUCCESS });

      history.push('/painel/agricultura');
    } else {
      configSession = {
        fazendas,
        // ...licenca

      }
      yield storage.set(CONFIG_SESSION, JSON.stringify(configSession));

      yield put({ type: CREATE_START_SESSION_SUCCESS });

      history.push('/selecionarFazenda');
    }


  } catch (error) {
    yield put({ type: CREATE_START_SESSION_FAILURE })

    AlertError('Falha ao carregar licença! Tente novamente...');
    httpAuthService.logout({ path });
    history.push('/login');
  }

}

export default function* watchCreateStartSession() {
  yield takeLatest(CREATE_START_SESSION, sagaCreateStartSession)
}
