


import watchNewTipoEmbalagemCadastro from './saga/new';
import watchFindTipoEmbalagemCadastro from './saga/find';
import watchCreateTipoEmbalagemCadastro from './saga/create';
import watchListAllTipoEmbalagemCadastro from './saga/listAll';
import watchListTipoEmbalagemCadastro from './saga/listTable';
import watchDeleteTipoEmbalagemCadastro from './saga/delete';

export const tipoEmbalagemCadastroSaga = [
    watchNewTipoEmbalagemCadastro(),
    watchFindTipoEmbalagemCadastro(),
    watchCreateTipoEmbalagemCadastro(),
    watchListAllTipoEmbalagemCadastro(),
    watchListTipoEmbalagemCadastro(),
    watchDeleteTipoEmbalagemCadastro(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
