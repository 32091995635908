import React, { Component } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
          }
        }
      }
    }
    return false;
  };

  closeAllMenu() {
    this.setState({
      MenuCadastroState: false,
      MenuFinanceiroState: false,
      // MenuEstoqueState: false,
    });
    this.props.funcCloseMenu();
  }
  
  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
              >
                <div id="navigation">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/painel/agricultura"
                        onClick={e => {
                          this.closeAllMenu();
                        }}>
                        <i className="mdi mdi-view-dashboard mr-2"></i>Painel Agrare
                      </Link>
                    </li>



                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ MenuCadastroState: !this.state.MenuCadastroState });
                        }}
                      >
                        <i className="fa fa-plus-square mr-2"></i>Cadastros
                      </Link>

                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.MenuCadastroState
                        })}
                      >

                        <Link to="/safraCadastro" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Safra
                      </Link>


                        <Link to="/talhaoCadastro" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Talhão
                        </Link>

                        <Link to="/procedimentoCadastro" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Procedimento
                        </Link>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({
                                EmailState: !this.state.EmailState
                              });
                            }}
                          >
                            Produto/Insumo <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: this.state.EmailState
                            })}
                            aria-labelledby="topnav-email"
                          >
                            <Link to="/produtoInsumoCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Cadastro
                            </Link>
                            <hr />
                            <Link to="/tipoEmbalagemCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Tipo de Embalagem
                            </Link>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({
                                EmailState: !this.state.EmailState
                              });
                            }}
                          >
                            Pessoas <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: this.state.EmailState
                            })}
                            aria-labelledby="topnav-email"
                          >
                            <Link to="email-inbox" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Cliente
                            </Link>
                            <Link to="email-read" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Fornecedor
                            </Link>


                            <div className="dropdown">
                              <Link
                                to="/#"
                                className="dropdown-item dropdown-toggle arrow-none"
                                onClick={e => {
                                  e.preventDefault();
                                  this.setState({
                                    EmailState: !this.state.EmailState
                                  });
                                }}
                              >
                                Funcionário <div className="arrow-down"></div>
                              </Link>
                              <div
                                className={classname("dropdown-menu", {
                                  show: this.state.EmailState
                                })}
                                aria-labelledby="topnav-email"
                              >
                                <Link to="/funcionarioCadastro" className="dropdown-item"
                                  onClick={e => {
                                    this.closeAllMenu();
                                  }}>
                                  Cadastro
                            </Link>
                                <hr />
                                <Link to="/funcionarioCargoCadastro" className="dropdown-item"
                                  onClick={e => {
                                    this.closeAllMenu();
                                  }}>
                                  Cargo
                            </Link>
                              </div>
                            </div>


                          </div>
                        </div>


                        <Link to="/patrimonioCadastro" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Patrimônio
                        </Link>


                        <Link to="/despesaDiretaCadastro" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Despesa Direta
                        </Link>
                      </div>
                    </li>


                    <li className="nav-item">
                      <Link className="nav-link" to="/plantioManejo"
                        onClick={e => {
                          this.closeAllMenu();
                        }}>
                        <i className="fa fa-envira mr-2"></i>Plantio e Manejo
                      </Link>
                    </li>


                    <li className="nav-item">
                      <Link className="nav-link" to="/colheita"
                        onClick={e => {
                          this.closeAllMenu();
                        }}>
                        <i className="fa fa-truck mr-2"></i>Colheita
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <Link className="nav-link" to="/"
                      onClick={e => {
                        this.closeAllMenu();
                      }}>
                        <i className="fa fa-thermometer-0 mr-2"></i>Pluviômetro Digital
                      </Link>
                    </li> */}



                    {/* <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ MenuEstoqueState: !this.state.MenuEstoqueState });
                        }}
                      >
                        <i className="fa fa-tags mr-2"></i>Estoque
                      </Link>

                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.MenuEstoqueState
                        })}
                      >

                        <Link to="calendar" className="dropdown-item"
                      onClick={e => {
                        this.closeAllMenu();
                      }}>
                          Entrada de Estoque
                      </Link>

                        <hr />

                        <Link to="calendar" className="dropdown-item"
                      onClick={e => {
                        this.closeAllMenu();
                      }}>
                          Natureza da Operação
                        </Link>

                        <Link to="calendar" className="dropdown-item"
                      onClick={e => {
                        this.closeAllMenu();
                      }}>
                          Relatório de Movimentação do Estoque
                        </Link>
                      </div>
                    </li> */}


                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ MenuFinanceiroState: !this.state.MenuFinanceiroState });
                        }}
                      >
                        <i className="fa fa-money mr-2"></i>Financeiro
                      </Link>

                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.MenuFinanceiroState
                        })}
                      >

                        <Link to="calendar" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Contas à Pagar
                      </Link>

                        <Link to="calendar" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Contas à Receber
                        </Link>

                        <Link to="calendar" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Doc. Múltiplos Vínculos
</Link>

                        <Link to="calendar" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Controle de Banco
</Link>

                        <Link to="calendar" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Controle de Caixa
</Link>

                        <Link to="calendar" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Fluxo de Caixa - À Realizar
</Link>

                        <Link to="calendar" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Fluxo de Caixa - Realizado
</Link>

                        <Link to="calendar" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Plano de Contas Gerencial
</Link>

                        <hr />

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({
                                EmailState: !this.state.EmailState
                              });
                            }}
                          >
                            Cadastro de Contas <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: this.state.EmailState
                            })}
                            aria-labelledby="topnav-email"
                          >
                            <Link to="email-inbox" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Banco
                            </Link>
                            <Link to="email-compose" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Caixa
                            </Link>
                            <Link to="email-compose" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Cartão
                            </Link>
                            <Link to="email-compose" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Cheque Pré Datado
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>


                  </ul>
                </div>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Navbar);
