import { takeLatest, put, call } from 'redux-saga/effects';

import { findFuncionarioCargoCadastro } from '../service';
import {
  FIND_FUNCIONARIO_CARGO_CADASTRO,
  FIND_FUNCIONARIO_CARGO_CADASTRO_PENDING,
  FIND_FUNCIONARIO_CARGO_CADASTRO_SUCCESS,
  FIND_FUNCIONARIO_CARGO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindFuncionarioCargoCadastro(action) {
  yield put({ type: FIND_FUNCIONARIO_CARGO_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findFuncionarioCargoCadastro, action.id);

    yield put({ type: FIND_FUNCIONARIO_CARGO_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_FUNCIONARIO_CARGO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindFuncionarioCargoCadastro() {
  yield takeLatest(FIND_FUNCIONARIO_CARGO_CADASTRO, sagaFindFuncionarioCargoCadastro)
}
