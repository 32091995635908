import watchNewSafraCadastro from './saga/new';
import watchFindSafraCadastro from './saga/find';
import watchCreateSafraCadastro from './saga/create';
import watchListSafraCadastro from './saga/listTable';
import watchDeleteSafraCadastro from './saga/delete';

export const safraCadastroSaga = [
    watchNewSafraCadastro(),
    watchFindSafraCadastro(),
    watchCreateSafraCadastro(),
    watchListSafraCadastro(),
    watchDeleteSafraCadastro(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
