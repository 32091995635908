import { takeLatest, put, call } from 'redux-saga/effects';

import { findPatrimonioCadastro } from '../service';
import {
  FIND_PATRIMONIO_CADASTRO,
  FIND_PATRIMONIO_CADASTRO_PENDING,
  FIND_PATRIMONIO_CADASTRO_SUCCESS,
  FIND_PATRIMONIO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindPatrimonioCadastro(action) {
  yield put({ type: FIND_PATRIMONIO_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findPatrimonioCadastro, action.id);
    
    yield put({ type: FIND_PATRIMONIO_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_PATRIMONIO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindPatrimonioCadastro() {
  yield takeLatest(FIND_PATRIMONIO_CADASTRO, sagaFindPatrimonioCadastro)
}
