import watchFindConfigAgricultura from './saga/find';
import watchUpdateConfigAgricultura from './saga/update';

export const configAgriculturaSaga = [
    watchFindConfigAgricultura(),
    watchUpdateConfigAgricultura(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
