


import watchNewFuncionarioCargoCadastro from './saga/new';
import watchFindFuncionarioCargoCadastro from './saga/find';
import watchCreateFuncionarioCargoCadastro from './saga/create';
import watchListAllFuncionarioCargoCadastro from './saga/listAll';
import watchListFuncionarioCargoCadastro from './saga/listTable';
import watchDeleteFuncionarioCargoCadastro from './saga/delete';

export const funcionarioCargoCadastroSaga = [
    watchNewFuncionarioCargoCadastro(),
    watchFindFuncionarioCargoCadastro(),
    watchCreateFuncionarioCargoCadastro(),
    watchListAllFuncionarioCargoCadastro(),
    watchListFuncionarioCargoCadastro(),
    watchDeleteFuncionarioCargoCadastro(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
