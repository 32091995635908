


import watchNewFazendaCadastro from './saga/new';
import watchFindFazendaCadastro from './saga/find';
import watchCreateFazendaCadastro from './saga/create';
import watchListFazendaCadastro from './saga/listTable';
import watchDeleteFazendaCadastro from './saga/delete';

export const fazendaCadastroSaga = [
    watchNewFazendaCadastro(),
    watchFindFazendaCadastro(),
    watchCreateFazendaCadastro(),
    watchListFazendaCadastro(),
    watchDeleteFazendaCadastro(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
