import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createTipoEmbalagemCadastro, updateTipoEmbalagemCadastro } from '../service';
import {
  CREATE_TIPO_EMBALAGEM_CADASTRO,
  CREATE_TIPO_EMBALAGEM_CADASTRO_PENDING,
  CREATE_TIPO_EMBALAGEM_CADASTRO_SUCCESS,
  CREATE_TIPO_EMBALAGEM_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateTipoEmbalagemCadastro(action) {
  yield put({ type: CREATE_TIPO_EMBALAGEM_CADASTRO_PENDING })

  try {
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createTipoEmbalagemCadastro, action.data);
    } else {
      yield call(updateTipoEmbalagemCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_TIPO_EMBALAGEM_CADASTRO_SUCCESS, registro: action.data })

    history.push('/tipoEmbalagemCadastro');

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_TIPO_EMBALAGEM_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateTipoEmbalagemCadastro() {
  yield takeLatest(CREATE_TIPO_EMBALAGEM_CADASTRO, sagaCreateTipoEmbalagemCadastro)
}
