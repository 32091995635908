import { path, httpService } from '@commons';

///////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/dash/agro' };
///////////////////////////////////////////////////////////////////////////////


export function getTotais(propriedade, safra, inicio, fim) {
    return httpService.get({ ...args, args: '/totais/' + propriedade + '/' + safra + '?inicio=' + inicio + '&fim=' + fim });
}

export function getGraficoCustoProducaoPorTalhao(propriedade, safra, inicio, fim) {
    return httpService.get({ ...args, args: '/graficoCustoProducaoPorTalhao/' + propriedade + '/' + safra + '?inicio=' + inicio + '&fim=' + fim });
}

export function getGraficoCustoTipoInsumo(propriedade, safra, inicio, fim) {
    return httpService.get({ ...args, args: '/custo/grupo/insumo/' + propriedade + '/' + safra + '?inicio=' + inicio + '&fim=' + fim });
}

export function getListCustoInsumos(propriedade, safra, inicio, fim) {
    return httpService.get({ ...args, args: '/custo/insumo/' + propriedade + '/' + safra + '?inicio=' + inicio + '&fim=' + fim });
}

export function getListCustoMaquinarios(propriedade, safra, inicio, fim) {
    return httpService.get({ ...args, args: '/custo/maq/' + propriedade + '/' + safra + '?inicio=' + inicio + '&fim=' + fim });
}

export function getListCustoFuncionarios(propriedade, safra, inicio, fim) {
    return httpService.get({ ...args, args: '/custo/func/' + propriedade + '/' + safra + '?inicio=' + inicio + '&fim=' + fim });
}

export function getListCustoProcedimentos(propriedade, safra, inicio, fim) {
    return httpService.get({ ...args, args: '/custo/procedimento/' + propriedade + '/' + safra + '?inicio=' + inicio + '&fim=' + fim });
}

export function getListCustoDespesaDireta(propriedade, safra, inicio, fim) {
    return httpService.get({ ...args, args: '/custo/direto/' + propriedade + '/' + safra + '?inicio=' + inicio + '&fim=' + fim });
}