
export const NEW_TALHAO_CADASTRO = 'NEW_TALHAO_CADASTRO'
export const NEW_TALHAO_CADASTRO_SUCCESS = 'NEW_TALHAO_CADASTRO_SUCCESS'

export const CREATE_TALHAO_CADASTRO = 'CREATE_TALHAO_CADASTRO'
export const CREATE_TALHAO_CADASTRO_PENDING = 'CREATE_TALHAO_CADASTRO_PENDING'
export const CREATE_TALHAO_CADASTRO_SUCCESS = 'CREATE_TALHAO_CADASTRO_SUCCESS'
export const CREATE_TALHAO_CADASTRO_FAILURE = 'CREATE_TALHAO_CADASTRO_FAILURE'

export const TABLE_TALHAO_CADASTRO = 'TABLE_TALHAO_CADASTRO'
export const TABLE_TALHAO_CADASTRO_PENDING = 'TABLE_TALHAO_CADASTRO_PENDING'
export const TABLE_TALHAO_CADASTRO_SUCCESS = 'TABLE_TALHAO_CADASTRO_SUCCESS'
export const TABLE_TALHAO_CADASTRO_FAILURE = 'TABLE_TALHAO_CADASTRO_FAILURE'

export const LIST_TALHAO_CADASTRO = 'LIST_TALHAO_CADASTRO'
export const LIST_TALHAO_CADASTRO_PENDING = 'LIST_TALHAO_CADASTRO_PENDING'
export const LIST_TALHAO_CADASTRO_SUCCESS = 'LIST_TALHAO_CADASTRO_SUCCESS'
export const LIST_TALHAO_CADASTRO_FAILURE = 'LIST_TALHAO_CADASTRO_FAILURE'

export const FIND_TALHAO_CADASTRO = 'FIND_TALHAO_CADASTRO'
export const FIND_TALHAO_CADASTRO_PENDING = 'FIND_TALHAO_CADASTRO_PENDING'
export const FIND_TALHAO_CADASTRO_SUCCESS = 'FIND_TALHAO_CADASTRO_SUCCESS'
export const FIND_TALHAO_CADASTRO_FAILURE = 'FIND_TALHAO_CADASTRO_FAILURE'

export const DELETE_TALHAO_CADASTRO = 'DELETE_TALHAO_CADASTRO'
export const DELETE_TALHAO_CADASTRO_PENDING = 'DELETE_TALHAO_CADASTRO_PENDING'
export const DELETE_TALHAO_CADASTRO_SUCCESS = 'DELETE_TALHAO_CADASTRO_SUCCESS'
export const DELETE_TALHAO_CADASTRO_FAILURE = 'DELETE_TALHAO_CADASTRO_FAILURE'
