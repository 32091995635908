import { takeLatest, put, call } from 'redux-saga/effects';

import { calcCustoHomemHora  } from '../service';
import {
  FUNCIONARIO_CADASTRO_CALC_CUSTO,
  CREATE_FUNCIONARIO_CADASTRO_PENDING,
  CREATE_FUNCIONARIO_CADASTRO_SUCCESS,
  CREATE_FUNCIONARIO_CADASTRO_FAILURE,
} from '@handler';

import { AlertError } from '@components/common/AlertToast'

import { formatJsonRemoveEmptyOrNull } from '@components/common/format'

function* sagaCalcCustoHomemHora(action) {
  yield put({ type: CREATE_FUNCIONARIO_CADASTRO_PENDING })

  try {
    const registro = yield call(calcCustoHomemHora, formatJsonRemoveEmptyOrNull(action.data));

    yield put({ type: CREATE_FUNCIONARIO_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {
    yield put({ type: CREATE_FUNCIONARIO_CADASTRO_FAILURE })

    AlertError('Falha ao calcular o custo...');
  }

}

export default function* watchCalcCustoHomemHora() {
  yield takeLatest(FUNCIONARIO_CADASTRO_CALC_CUSTO, sagaCalcCustoHomemHora)
}
