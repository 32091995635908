export const PRODUTO_INSUMO_GRUPO_ENUM = [
    {
        id: 'ACARICIDA',
        descricao: "Acaricida",
        idBD: 0
    },
    {
        id: 'ADJUVANTE',
        descricao: "Adjuvante",
        idBD: 1
    },
    {
        id: 'ADUBO',
        descricao: "Adubo",
        idBD: 2
    },
    {
        id: 'ADUBO_FOLIAR',
        descricao: "Adubo Foliar",
        idBD: 17
    },
    {
        id: 'DESSECANTE',
        descricao: "Dessecante",
        idBD: 3
    },
    {
        id: 'FERTILIZANTE',
        descricao: "Fertilizante",
        idBD: 4
    },
    {
        id: 'FUNGICIDA',
        descricao: "Fungicida",
        idBD: 5
    },
    {
        id: 'GRAOS',
        descricao: "Grãos",
        idBD: 6
    },
    {
        id: 'HERBICIDA',
        descricao: "Herbicida",
        idBD: 7
    },
    {
        id: 'INSETICIDA',
        descricao: "Inseticida",
        idBD: 8
    },
    {
        id: 'ITEM_MANUTENCAO',
        descricao: "Item Manutenção",
        idBD: 9
    },
    {
        id: 'MATERIAL_EPI',
        descricao: "Material EPI",
        idBD: 10
    },
    {
        id: 'MEDICAMENTO',
        descricao: "Medicamento",
        idBD: 11
    },
    {
        id: 'RACAO',
        descricao: "Ração",
        idBD: 12
    },
    {
        id: 'SAL_MINERAL',
        descricao: "Sal Mineral",
        idBD: 13
    },
    {
        id: 'SEMENTE_E_MUDA',
        descricao: "Semente e Muda",
        idBD: 14
    },
    {
        id: 'SUPRIMENTO_MINERAL',
        descricao: "Suprimento Mineral",
        idBD: 15
    },
    {
        id: 'OUTROS_INSUMOS',
        descricao: "Outro",
        idBD: 16
    },
];