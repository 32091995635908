import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createProdutoInsumoCadastro, updateProdutoInsumoCadastro } from '../service';
import {
  CREATE_PRODUTO_INSUMO_CADASTRO,
  CREATE_PRODUTO_INSUMO_CADASTRO_PENDING,
  CREATE_PRODUTO_INSUMO_CADASTRO_SUCCESS,
  CREATE_PRODUTO_INSUMO_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

import { formatJsonRemoveEmptyOrNull } from '@components/common/format'

function* sagaCreateProdutoInsumoCadastro(action) {
  yield put({ type: CREATE_PRODUTO_INSUMO_CADASTRO_PENDING })

  try {
    if (action.data.grupo !== 'SEMENTE_E_MUDA') {
      action.data = {
        ...action.data,
        tipoEmbalagem: null,
        qtdSaca: 0,
        unidadeVenda: null,
      }
    }
    if (action.data.grupo !== 'ACARICIDA'
      && action.data.grupo !== 'ADJUVANTE'
      && action.data.grupo !== 'ADUBO_FOLIAR'
      && action.data.grupo !== 'DESSECANTE'
      && action.data.grupo !== 'FUNGICIDA'
      && action.data.grupo !== 'HERBICIDA'
      && action.data.grupo !== 'INSETICIDA'
      && action.data.grupo !== 'MEDICAMENTO') {
      action.data = {
        ...action.data,
        dosagemHa: 0,
      }
    }
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createProdutoInsumoCadastro, formatJsonRemoveEmptyOrNull(action.data));
    } else {
      yield call(updateProdutoInsumoCadastro,
        formatJsonRemoveEmptyOrNull(
          {
            ...action.data,
            id: action.data.id
          }
        )
      );
    }

    yield put({ type: CREATE_PRODUTO_INSUMO_CADASTRO_SUCCESS, registro: action.data })

    history.push('/produtoInsumoCadastro');

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_PRODUTO_INSUMO_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateProdutoInsumoCadastro() {
  yield takeLatest(CREATE_PRODUTO_INSUMO_CADASTRO, sagaCreateProdutoInsumoCadastro)
}
