


import watchNewColheita from './saga/new';
import watchFindColheita from './saga/find';
import watchCreateColheita from './saga/create';
import watchListColheita from './saga/listTable';
import watchDeleteColheita from './saga/delete';

export const colheitaSaga = [
    watchNewColheita(),
    watchFindColheita(),
    watchCreateColheita(),
    watchListColheita(),
    watchDeleteColheita(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
