import * as Yup from 'yup';

const newRegistro = {
    formaLancColheita: null,
    formaLancMaquinario: null,
    formaLancPlantio: null
};

const validation = Yup.object().shape({
    formaLancColheita: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    formaLancMaquinario: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    formaLancPlantio: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
});

export const configAgricultura = {
    newRegistro,
    validation
}