import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createFuncionarioCadastro, updateFuncionarioCadastro } from '../service';
import {
  CREATE_FUNCIONARIO_CADASTRO,
  CREATE_FUNCIONARIO_CADASTRO_PENDING,
  CREATE_FUNCIONARIO_CADASTRO_SUCCESS,
  CREATE_FUNCIONARIO_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

import { formatJsonRemoveEmptyOrNull } from '@components/common/format'

function* sagaCreateFuncionarioCadastro(action) {
  yield put({ type: CREATE_FUNCIONARIO_CADASTRO_PENDING })

  try {
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createFuncionarioCadastro, formatJsonRemoveEmptyOrNull(action.data));
    } else {
      yield call(updateFuncionarioCadastro,
        formatJsonRemoveEmptyOrNull({
          ...action.data,
          id: action.data.id
        })
      );
    }

    yield put({ type: CREATE_FUNCIONARIO_CADASTRO_SUCCESS, registro: action.data })

    history.push('/funcionarioCadastro');

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_FUNCIONARIO_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateFuncionarioCadastro() {
  yield takeLatest(CREATE_FUNCIONARIO_CADASTRO, sagaCreateFuncionarioCadastro)
}
