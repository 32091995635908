import * as Yup from 'yup';

const newRegistro = {
    data: '',
    obs: '',
    talhao: null,
    cultura: null,
    qtd: 0,
    sacas: 0,
    valorSaca: 0,
    media: 0,
    totalColheita: 0,
};

const validation = Yup.object().shape({
    data: Yup.date()
        .required('Este campo é obrigatório'),
    talhao: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
        cultura: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Required"),
        }),
        valorSaca: Yup.number()
        .positive('Por Favor, informe um valor maior que zero')
        .transform(value => (isNaN(value) ? 0.0 : value))
        .required('Este campo é obrigatório'),
});

export const colheita = {
    newRegistro,
    validation
}