import moment from "moment";

export function calculaCustoHora(horario, custo) {
    let [hora, minuto] = horario.split(':').map(v => parseInt(v));
    if (!minuto) {
        minuto = 0;
    }
    // soma toda a hora em minutos e multiplica por (custo por minuto)
    return (minuto + (hora * 60)) * (custo / 60);
}

export function calcularDiffHorasDoubleToHHMM(horaInicial, horaFinal) {
    var diff = (parseFloat(horaFinal) - parseFloat(horaInicial)).toFixed(1);
    var times = diff.split('.', 2)
    var horas = times[0];
    if (horas.length === 1) {
        horas = '0' + horas;
    }
    var segundos = times[1] * 6;

    var horasEmMinutos = horas * 60;

    var minutosTotais = horasEmMinutos + segundos;

    return moment().startOf('day').minutes(minutosTotais).format('HH:mm');
}