import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createPatrimonioCadastro, updatePatrimonioCadastro } from '../service';
import {
  CREATE_PATRIMONIO_CADASTRO,
  CREATE_PATRIMONIO_CADASTRO_PENDING,
  CREATE_PATRIMONIO_CADASTRO_SUCCESS,
  CREATE_PATRIMONIO_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertWarning, AlertError } from '@components/common/AlertToast'

import { formatJsonRemoveEmptyOrNull } from '@components/common/format'

function* sagaCreatePatrimonioCadastro(action) {
  yield put({ type: CREATE_PATRIMONIO_CADASTRO_PENDING })

  try {
    if (action.data.tipo === 'MAQUINA' && (action.data.tipoMaquina === undefined || action.data.tipoMaquina === null ||
      action.data.tipoMaquina === '')) {
      AlertWarning('Selecione o campo tipo de máquina');
      yield put({ type: CREATE_PATRIMONIO_CADASTRO_FAILURE, registro: action.data })
    } else {
      if (action.data.id === undefined || action.data.id === null) {
        yield call(createPatrimonioCadastro, formatJsonRemoveEmptyOrNull(action.data));
      } else {
        yield call(updatePatrimonioCadastro,
          formatJsonRemoveEmptyOrNull({
            ...action.data,
            id: action.data.id
          }
          ));
      }

      yield put({ type: CREATE_PATRIMONIO_CADASTRO_SUCCESS, registro: action.data })

      history.push('/patrimonioCadastro');

      AlertSuccess('Registro salvo com sucesso!');
    }
  } catch (error) {
    yield put({ type: CREATE_PATRIMONIO_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreatePatrimonioCadastro() {
  yield takeLatest(CREATE_PATRIMONIO_CADASTRO, sagaCreatePatrimonioCadastro)
}
