
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { httpAuthService } from '@commons';
import { path } from '@commons/path';

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }


    render() {
        const { loginReducer, startSessionReducer } = this.props;

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <i className="fa fa-user-circle font-size-17" style={{ marginRight: '5px', color: '#175B28' }} />
                        <span style={{ color: '#175B28' }}>{startSessionReducer.configSession == null || startSessionReducer.configSession.fazendas == null || startSessionReducer.configSession.fazendaSelecionada == null ?
                            loginReducer.user.nome.split(' ').shift() + "  " :
                            loginReducer.user.nome.split(' ').shift() + " - " + startSessionReducer.configSession.fazendaSelecionada.nome + "  "
                        }</span>
                        <i className="fa fa-angle-down" style={{ color: '#175B28' }} />
                    </DropdownToggle>
                    <DropdownMenu right>
                        {startSessionReducer.configSession == null || startSessionReducer.configSession.fazendas == null || startSessionReducer.configSession.fazendas.length <= 1 ?
                            <React.Fragment /> :
                            <React.Fragment>
                                <DropdownItem tag="a" href="/selecionarFazenda"><i className="fa fa-exchange-alt font-size-17 align-middle mr-1"></i>Trocar de Fazenda</DropdownItem>
                                <div className="dropdown-divider"></div>
                            </React.Fragment>
                        }

                        <DropdownItem tag="a" href="#"><i className="mdi mdi-account-circle font-size-17 align-middle mr-1"></i>Meu perfil</DropdownItem>
                        <DropdownItem tag="a" href="#"><i className="mdi mdi-wallet font-size-17 align-middle mr-1"></i>Minhas Fazendas</DropdownItem>
                        <DropdownItem tag="a" href="/config"><i className="mdi mdi-settings font-size-17 align-middle mr-1"></i>Configurações</DropdownItem>
                        <DropdownItem tag="a" href="https://help.agrare.com.br" target='_blank'><i className="mdi mdi-help font-size-17 align-middle mr-1"></i>Ajuda Online</DropdownItem>
                        <div className="dropdown-divider"></div>
                        <Link
                            to="/login"
                            onClick={() => httpAuthService.logout({ path })}
                            className="dropdown-item">
                            <i className="mdi mdi-logout font-size-17 align-middle mr-1"></i>
                            <span>Sair da Fazenda</span>
                        </Link>

                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

export function mapStateToProps(state) {
    const { loginReducer, startSessionReducer } = state.entities;
    return { loginReducer, startSessionReducer };
};

function mapDispatchToProps(dispatch) {
    return {}
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileMenu))




