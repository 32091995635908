import { takeLatest, put } from 'redux-saga/effects';
import moment from "moment";

import {
  plantioManejo,
  NEW_PLANTIO_MANEJO,
  NEW_PLANTIO_MANEJO_SUCCESS,
} from '@handler';

function* sagaNewPlantioManejo(action) {

  yield put({
    type: NEW_PLANTIO_MANEJO_SUCCESS, registro: {
      ...plantioManejo.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
      talhao: action.talhao
    }
  });

}

export default function* watchNewPlantioManejo() {
  yield takeLatest(NEW_PLANTIO_MANEJO, sagaNewPlantioManejo)
}
