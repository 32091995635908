import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/talhao' };
/////////////////////////////////////////////////////////////////////////////////

export function createTalhaoCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateTalhaoCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listTalhaoCadastro(param) {
  return httpService.get({...args, args: param});
}

export function listTalhaoBySafra(idPropriedade, idSafra) {
  return httpService.get({...args, args: '/listBySafra/' + idPropriedade + '/' + idSafra});
}

export function listAllTalhaoAtivo(idPropriedade) {
  return httpService.get({...args, args: `?size=2000&page=0&propriedade=${idPropriedade}&status=true`});
}

export function findTalhaoCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function deleteTalhaoCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

