import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/ativColheita' };
/////////////////////////////////////////////////////////////////////////////////

export function createColheita(payload) {
  return httpService.post(args, payload);
}

export function updateColheita(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listColheita(param) {
  return httpService.get({...args, args: param});
}

export function findColheita(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function deleteColheita(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function getTotalSafra(idPropriedade, idSafra) {
  return httpService.get({...args, args: '/totalSafra/' + idPropriedade + '/' + idSafra});
}

export function getTotalSacas(idPropriedade, idSafra, idTalhao) {
  return httpService.get({...args, args: '/totalSacas/' + idPropriedade + '/' + idSafra + '/' + idTalhao});
}

export function getCulturasAColher(idPropriedade, idSafra, idTalhao) {
  return httpService.get({...args, args: '/culturasAColher/' + idPropriedade + '/' + idSafra + '/' + idTalhao});
}

