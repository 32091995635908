import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import { deleteProdutoInsumoCadastro } from '../service';
import {
  DELETE_PRODUTO_INSUMO_CADASTRO,
  DELETE_PRODUTO_INSUMO_CADASTRO_PENDING,
  DELETE_PRODUTO_INSUMO_CADASTRO_SUCCESS,
  DELETE_PRODUTO_INSUMO_CADASTRO_FAILURE,
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaDeleteProdutoInsumoCadastro(action) {
  yield put({ type: DELETE_PRODUTO_INSUMO_CADASTRO_PENDING, id: action.id })

  try {
    const a = yield call(deleteProdutoInsumoCadastro, action.id);

    if (a.status !== 204) {
      throw new Error('API delete request failed');
    }
    yield put({ type: DELETE_PRODUTO_INSUMO_CADASTRO_SUCCESS, id: action.id });

    swal('Excluído!', '', 'success').then(() => {
      history.go(0)
    });

  } catch (error) {

    yield put({ type: DELETE_PRODUTO_INSUMO_CADASTRO_FAILURE });

    AlertError('Falha ao exlcuir o registro! Tente novamente...');
  }
}

export default function* watchDeleteProdutoInsumoCadastro() {
  yield takeLatest(DELETE_PRODUTO_INSUMO_CADASTRO, sagaDeleteProdutoInsumoCadastro)
}
