import React from 'react';

import { DataTable } from 'primereact/datatable';

class TableNoLazy extends React.Component {

    constructor(props) {
        super(props);

        this.state = {rows: 50};
    }

    render() {

        const refDt = React.createRef();
        const { paginator, lazy } = this.props;

        return (
            <>
                {this.props.list &&
                    <DataTable ref={refDt} value={this.props.list} paginator={paginator} lazy={lazy}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Total de registros: {totalRecords}" rows={this.state.rows} rowsPerPageOptions={[50, 100, 500]}
                        className='table table-striped my-4 w-100' totalRecords={this.props.list.length}
                        first={0} onPage={this.onPage} emptyMessage="Nenhum registro encontrado" autoLayout={true}
                    >
                        {this.props.children}

                    </DataTable>
                }
            </>
        )

    }
}

TableNoLazy.defaultProps = {
    paginator: true,
    lazy: false,
    auto: true,
    onPage: () => { },
};

export { TableNoLazy };