import { takeLatest, put, call } from 'redux-saga/effects';

import { findFuncionarioCadastro } from '../service';
import {
  FIND_FUNCIONARIO_CADASTRO,
  FIND_FUNCIONARIO_CADASTRO_PENDING,
  FIND_FUNCIONARIO_CADASTRO_SUCCESS,
  FIND_FUNCIONARIO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindFuncionarioCadastro(action) {
  yield put({ type: FIND_FUNCIONARIO_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findFuncionarioCadastro, action.id);

    yield put({ type: FIND_FUNCIONARIO_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_FUNCIONARIO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindFuncionarioCadastro() {
  yield takeLatest(FIND_FUNCIONARIO_CADASTRO, sagaFindFuncionarioCadastro)
}
