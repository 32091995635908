import React from "react";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              © {new Date().getFullYear()} 
              {" "} - Agrare - Tecnologia e Gestão no Campo - CNPJ: 35.842.386/0001-63.
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
