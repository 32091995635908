export const TOTAIS_DASHBOARD = 'TOTAIS_DASHBOARD'
export const TOTAIS_DASHBOARD_PENDING = 'TOTAIS_DASHBOARD_PENDING'
export const TOTAIS_DASHBOARD_SUCCESS = 'TOTAIS_DASHBOARD_SUCCESS'
export const TOTAIS_DASHBOARD_FAILURE = 'TOTAIS_DASHBOARD_FAILURE'

export const GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD = 'GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD'
export const GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_PENDING = 'GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_PENDING'
export const GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_SUCCESS = 'GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_SUCCESS'
export const GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_FAILURE = 'GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_FAILURE'

export const GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD = 'GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD'
export const GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_PENDING = 'GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_PENDING'
export const GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_SUCCESS = 'GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_SUCCESS'
export const GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_FAILURE = 'GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_FAILURE'

export const CUSTO_LIST_TABLE_DASHBOARD = 'CUSTO_LIST_TABLE_DASHBOARD'
export const CUSTO_LIST_TABLE_DASHBOARD_PENDING = 'CUSTO_LIST_TABLE_DASHBOARD_PENDING'
export const CUSTO_LIST_TABLE_DASHBOARD_SUCCESS = 'CUSTO_LIST_TABLE_DASHBOARD_SUCCESS'
export const CUSTO_LIST_TABLE_DASHBOARD_FAILURE = 'CUSTO_LIST_TABLE_DASHBOARD_FAILURE'
