import { takeLatest, put, call } from 'redux-saga/effects';

import { findConfigAgricultura } from '../service';
import { storage } from '@commons/storage';
import {
  CONFIG_SESSION,
  FIND_CONFIG_AGRICULTURA,
  FIND_CONFIG_AGRICULTURA_PENDING,
  FIND_CONFIG_AGRICULTURA_SUCCESS,
  FIND_CONFIG_AGRICULTURA_FAILURE
} from '@handler';

function* sagaFindConfigAgricultura() {
  yield put({ type: FIND_CONFIG_AGRICULTURA_PENDING })

  try {
    const id = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;

    const registro = yield call(findConfigAgricultura, id);

    yield put({ type: FIND_CONFIG_AGRICULTURA_SUCCESS, registro: registro })

  } catch (error) {
    yield put({ type: FIND_CONFIG_AGRICULTURA_FAILURE })

  }
}

export default function* watchFindConfigAgricultura() {
  yield takeLatest(FIND_CONFIG_AGRICULTURA, sagaFindConfigAgricultura)
}
