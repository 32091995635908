import React from 'react';
import {
    Button
} from 'reactstrap';

// Sweet Alert
import swal from 'sweetalert';

class Confirm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            btn: this.props.btn,
            option: {
                title: 'Atenção!',
                text: this.props.text,
                label: this.props.label,
                icon: 'warning',
                cancelButtonText: "No, cancel it!",
                buttons: {
                    confirm: {
                        text: this.props.btn.name,
                        value: true,
                        visible: true,
                        className: 'bg-success',
                        closeModal: true
                    },
                    cancel: {
                        text: 'Cancelar',
                        visible: true,
                        className: 'bg-danger',
                        closeModal: true
                    },
                }
            }
        }
    }

    callback(isConfirm, swal) {

        if (!isConfirm) {
            swal('Operação Cancelada!', '', 'error');
        }
    }

    handleClick = (e) => {

        swal(this.state.option).then(
            (confirm) => {
                // press yes
                if (confirm) {
                    this.props.action(this.props.registro);
                    return this.callback(confirm, swal);
                }

                return this.callback(confirm, swal);
            }
        );
    }

    render() {
        return (
            <>
                <Button color={this.state.btn.color} onClick={this.handleClick}><i className={this.state.btn.icon} />
                    <span style={{ fontWeight: 'bold', marginLeft: '5px' }}>{this.state.btn.name}</span>
                </Button>
            </>
        );
    }

}

export { Confirm };