import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    status: true,
    cargo: null,
    custoHomemHora: 0,
    calculaCustoHomemHora: false,
    calcCargaHorariaMes: 0,
    calcSalario: 0,
    calcFerias: 0,
    calcDecimoTerceiro: 0,
    calcInssPerc: 0,
    calcInssValor: 0,
    calcFgtsPerc: 0,
    calcFgtsValor: 0,
    calcSindicatoPerc: 0,
    calcSindicatoValor: 0,
    calcOutrasDespUmPerc: 0,
    calcOutrasDespUmValor: 0,
    calcOutrasDespDoisValor: 0,
    calcCustoTotalMes: 0,
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(60, 'Campo deve ter menos do que 60 caracteres')
        .required('Este campo é obrigatório'),
    cargo: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
});

export const funcionarioCadastro = {
    newRegistro,
    validation
}