import jwt_decode from "jwt-decode";

const set = (token, data) => {
    sessionStorage.setItem(token, data);
}

const remove = (token) => {
    sessionStorage.removeItem(token);
}

const clear = () => {
    sessionStorage.clear();
}

const get = (token) => {
    return sessionStorage.getItem(token);
}

const content = (token) => {
    try {
        return jwt_decode(get(token));
    } catch (err) {
        return { }
    }
}

export const storage = {
    set,
    remove,
    get,
    content,
    clear
}
