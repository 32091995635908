import React, { useState } from 'react';

import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

export function InfoTooltip(props) {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const toggle = () => setPopoverOpen(!popoverOpen);

    return (
        <React.Fragment>
            <i className="fa fa-info-circle" id={`po${props.id}`} as="button" style={{ marginLeft: '3px', color: '#1C8E42', cursor: 'pointer' }}></i>
            <Popover placement="bottom" isOpen={popoverOpen} target={`po${props.id}`} toggle={toggle}>
                <PopoverHeader>{props.title}</PopoverHeader>
                <PopoverBody>{props.msg}</PopoverBody>
            </Popover>
        </React.Fragment>
    );
}