import { takeLatest, put } from 'redux-saga/effects';

import {
  produtoInsumoCadastro,
  NEW_PRODUTO_INSUMO_CADASTRO,
  NEW_PRODUTO_INSUMO_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewProdutoInsumoCadastro(action) {

  yield put({ type: NEW_PRODUTO_INSUMO_CADASTRO_SUCCESS, registro: produtoInsumoCadastro.newRegistro });
}

export default function* watchNewProdutoInsumoCadastro() {
  yield takeLatest(NEW_PRODUTO_INSUMO_CADASTRO, sagaNewProdutoInsumoCadastro)
}
