export const CONFIG_SESSION = 'CONFIG_SESSION'

export const CREATE_START_SESSION = 'CREATE_START_SESSION'
export const CREATE_START_SESSION_PENDING = 'CREATE_START_SESSION_PENDING'
export const CREATE_START_SESSION_SUCCESS = 'CREATE_START_SESSION_SUCCESS'
export const CREATE_START_SESSION_FAILURE = 'CREATE_START_SESSION_FAILURE'

export const SELECT_FAZENDA = 'SELECT_FAZENDA'
export const SELECT_START_SESSION_PENDING = 'SELECT_START_SESSION_PENDING'
export const SELECT_START_SESSION_SUCCESS = 'SELECT_START_SESSION_SUCCESS'
export const SELECT_START_SESSION_FAILURE = 'SELECT_START_SESSION_FAILURE'

export const SELECT_SAFRA_FAZENDA = 'SELECT_SAFRA_FAZENDA'
