import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllFuncionarioCargoCadastro } from '../service';
import {
  LIST_FUNCIONARIO_CARGO_CADASTRO,
  LIST_FUNCIONARIO_CARGO_CADASTRO_PENDING,
  LIST_FUNCIONARIO_CARGO_CADASTRO_SUCCESS,
  LIST_FUNCIONARIO_CARGO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListAllFuncionarioCargoCadastro(action) {
  yield put({ type: LIST_FUNCIONARIO_CARGO_CADASTRO_PENDING })

  try {
    const list = yield call(findAllFuncionarioCargoCadastro)
    
    yield put({ type: LIST_FUNCIONARIO_CARGO_CADASTRO_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_FUNCIONARIO_CARGO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllFuncionarioCargoCadastro() {
  yield takeLatest(LIST_FUNCIONARIO_CARGO_CADASTRO, sagaListAllFuncionarioCargoCadastro)
}