import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createFazendaCadastro } from '../service';
import {
  CREATE_FAZENDA_CADASTRO,
  CREATE_FAZENDA_CADASTRO_PENDING,
  CREATE_FAZENDA_CADASTRO_SUCCESS,
  CREATE_FAZENDA_CADASTRO_FAILURE
} from '../actionTypes';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateFazendaCadastro(action) {
  yield put({ type: CREATE_FAZENDA_CADASTRO_PENDING })

  try {
    const newRegistro = yield call(createFazendaCadastro,
      {
        ...action.data,
        propriedade: {
          id: 1 // TODO: trocar id propriedade ativa
        }
      });

    yield put({ type: CREATE_FAZENDA_CADASTRO_SUCCESS, registro: newRegistro });

    history.push('/fazendaCadastro');

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_FAZENDA_CADASTRO_FAILURE })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateFazendaCadastro() {
  yield takeLatest(CREATE_FAZENDA_CADASTRO, sagaCreateFazendaCadastro)
}
