
export const NEW_FUNCIONARIO_CARGO_CADASTRO = 'NEW_FUNCIONARIO_CARGO_CADASTRO'
export const NEW_FUNCIONARIO_CARGO_CADASTRO_SUCCESS = 'NEW_FUNCIONARIO_CARGO_CADASTRO_SUCCESS'

export const CREATE_FUNCIONARIO_CARGO_CADASTRO = 'CREATE_FUNCIONARIO_CARGO_CADASTRO'
export const CREATE_FUNCIONARIO_CARGO_CADASTRO_PENDING = 'CREATE_FUNCIONARIO_CARGO_CADASTRO_PENDING'
export const CREATE_FUNCIONARIO_CARGO_CADASTRO_SUCCESS = 'CREATE_FUNCIONARIO_CARGO_CADASTRO_SUCCESS'
export const CREATE_FUNCIONARIO_CARGO_CADASTRO_FAILURE = 'CREATE_FUNCIONARIO_CARGO_CADASTRO_FAILURE'

export const TABLE_FUNCIONARIO_CARGO_CADASTRO = 'TABLE_FUNCIONARIO_CARGO_CADASTRO'
export const TABLE_FUNCIONARIO_CARGO_CADASTRO_PENDING = 'TABLE_FUNCIONARIO_CARGO_CADASTRO_PENDING'
export const TABLE_FUNCIONARIO_CARGO_CADASTRO_SUCCESS = 'TABLE_FUNCIONARIO_CARGO_CADASTRO_SUCCESS'
export const TABLE_FUNCIONARIO_CARGO_CADASTRO_FAILURE = 'TABLE_FUNCIONARIO_CARGO_CADASTRO_FAILURE'

export const LIST_FUNCIONARIO_CARGO_CADASTRO = 'LIST_FUNCIONARIO_CARGO_CADASTRO'
export const LIST_FUNCIONARIO_CARGO_CADASTRO_PENDING = 'LIST_FUNCIONARIO_CARGO_CADASTRO_PENDING'
export const LIST_FUNCIONARIO_CARGO_CADASTRO_SUCCESS = 'LIST_FUNCIONARIO_CARGO_CADASTRO_SUCCESS'
export const LIST_FUNCIONARIO_CARGO_CADASTRO_FAILURE = 'LIST_FUNCIONARIO_CARGO_CADASTRO_FAILURE'

export const FIND_FUNCIONARIO_CARGO_CADASTRO = 'FIND_FUNCIONARIO_CARGO_CADASTRO'
export const FIND_FUNCIONARIO_CARGO_CADASTRO_PENDING = 'FIND_FUNCIONARIO_CARGO_CADASTRO_PENDING'
export const FIND_FUNCIONARIO_CARGO_CADASTRO_SUCCESS = 'FIND_FUNCIONARIO_CARGO_CADASTRO_SUCCESS'
export const FIND_FUNCIONARIO_CARGO_CADASTRO_FAILURE = 'FIND_FUNCIONARIO_CARGO_CADASTRO_FAILURE'

export const DELETE_FUNCIONARIO_CARGO_CADASTRO = 'DELETE_FUNCIONARIO_CARGO_CADASTRO'
export const DELETE_FUNCIONARIO_CARGO_CADASTRO_PENDING = 'DELETE_FUNCIONARIO_CARGO_CADASTRO_PENDING'
export const DELETE_FUNCIONARIO_CARGO_CADASTRO_SUCCESS = 'DELETE_FUNCIONARIO_CARGO_CADASTRO_SUCCESS'
export const DELETE_FUNCIONARIO_CARGO_CADASTRO_FAILURE = 'DELETE_FUNCIONARIO_CARGO_CADASTRO_FAILURE'
