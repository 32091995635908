import * as Yup from 'yup';

const newRegistro = {
    descricao: '',
    status: true,
    utilizaFuncionario: false,
    utilizaMaquinario: false,
    utilizaProdutoInsumo: false
};

const validation = Yup.object().shape({
    descricao: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(255, 'Campo deve ter menos do que 255 caracteres')
        .required('Este campo é obrigatório'),
});

export const procedimentoCadastro = {
    newRegistro,
    validation
}