import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import { deletePlantioManejoCustoDireto } from '../service';
import {
  DELETE_PLANTIO_MANEJO_CUSTO_DIRETO,
  DELETE_PLANTIO_MANEJO_PENDING,
  DELETE_PLANTIO_MANEJO_SUCCESS,
  DELETE_PLANTIO_MANEJO_FAILURE,
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaDeletePlantioManejoCustoDireto(action) {
  yield put({ type: DELETE_PLANTIO_MANEJO_PENDING, id: action.id })

  try {
    const a = yield call(deletePlantioManejoCustoDireto, action.id);

    if (a.status !== 204) {
      throw new Error('API delete request failed');
    }
    yield put({ type: DELETE_PLANTIO_MANEJO_SUCCESS, id: action.id });

    swal('Excluído!', '', 'success').then(() => {
      history.go(0)
    });

  } catch (error) {

    yield put({ type: DELETE_PLANTIO_MANEJO_FAILURE });

    AlertError('Falha ao exlcuir o registro! Tente novamente...');
  }
}

export default function* watchDeletePlantioManejoCustoDireto() {
  yield takeLatest(DELETE_PLANTIO_MANEJO_CUSTO_DIRETO, sagaDeletePlantioManejoCustoDireto)
}
