
export const NEW_SAFRA_CADASTRO = 'NEW_SAFRA_CADASTRO'
export const NEW_SAFRA_CADASTRO_PENDING = 'NEW_SAFRA_CADASTRO_PENDING'
export const NEW_SAFRA_CADASTRO_SUCCESS = 'NEW_SAFRA_CADASTRO_SUCCESS'
export const NEW_SAFRA_CADASTRO_FAILURE = 'NEW_SAFRA_CADASTRO_FAILURE'

export const CREATE_SAFRA_CADASTRO = 'CREATE_SAFRA_CADASTRO'
export const CREATE_SAFRA_CADASTRO_PENDING = 'CREATE_SAFRA_CADASTRO_PENDING'
export const CREATE_SAFRA_CADASTRO_SUCCESS = 'CREATE_SAFRA_CADASTRO_SUCCESS'
export const CREATE_SAFRA_CADASTRO_FAILURE = 'CREATE_SAFRA_CADASTRO_FAILURE'

export const TABLE_SAFRA_CADASTRO = 'TABLE_SAFRA_CADASTRO'
export const TABLE_SAFRA_CADASTRO_PENDING = 'TABLE_SAFRA_CADASTRO_PENDING'
export const TABLE_SAFRA_CADASTRO_SUCCESS = 'TABLE_SAFRA_CADASTRO_SUCCESS'
export const TABLE_SAFRA_CADASTRO_FAILURE = 'TABLE_SAFRA_CADASTRO_FAILURE'

export const LIST_SAFRA_CADASTRO = 'LIST_SAFRA_CADASTRO'
export const LIST_SAFRA_CADASTRO_PENDING = 'LIST_SAFRA_CADASTRO_PENDING'
export const LIST_SAFRA_CADASTRO_SUCCESS = 'LIST_SAFRA_CADASTRO_SUCCESS'
export const LIST_SAFRA_CADASTRO_FAILURE = 'LIST_SAFRA_CADASTRO_FAILURE'

export const FIND_SAFRA_CADASTRO = 'FIND_SAFRA_CADASTRO'
export const FIND_SAFRA_CADASTRO_PENDING = 'FIND_SAFRA_CADASTRO_PENDING'
export const FIND_SAFRA_CADASTRO_SUCCESS = 'FIND_SAFRA_CADASTRO_SUCCESS'
export const FIND_SAFRA_CADASTRO_FAILURE = 'FIND_SAFRA_CADASTRO_FAILURE'

export const DELETE_SAFRA_CADASTRO = 'DELETE_SAFRA_CADASTRO'
export const DELETE_SAFRA_CADASTRO_PENDING = 'DELETE_SAFRA_CADASTRO_PENDING'
export const DELETE_SAFRA_CADASTRO_SUCCESS = 'DELETE_SAFRA_CADASTRO_SUCCESS'
export const DELETE_SAFRA_CADASTRO_FAILURE = 'DELETE_SAFRA_CADASTRO_FAILURE'
