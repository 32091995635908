import * as Yup from 'yup';

const newRegistro = {
    data: '',
    obs: '',
    despDireta: null,
    valor: 0,
    talhoes: [],
    allTalhoes: false
};

const validation = Yup.object().shape({
    data: Yup.date()
        .required('Este campo é obrigatório'),
    despDireta: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Required"),
        }),
    valor: Yup.number()
        .positive('Por Favor, informe um valor maior que zero')
        .transform(value => (isNaN(value) ? 0.0 : value))
        .required('Este campo é obrigatório'),
});

export const custoDireto = {
    newRegistro,
    validation
}