
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { TableNoLazy } from '@components/form';
import { formatCheck, formatDateDDMMYYYY } from '@components/common/format';
import { Column } from 'primereact/column';
import { SELECT_SAFRA_FAZENDA } from '@handler';

class SafraMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            modalSafraOpen: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    componentDidMount() {
        if (this.props.startSessionReducer.configSession !== null && this.props.startSessionReducer.configSession !== undefined 
            && this.props.startSessionReducer.configSession.safras !== null && this.props.startSessionReducer.configSession.safras !== undefined
            && this.props.startSessionReducer.configSession.safras.length > 1 && this.props.startSessionReducer.configSession.safraAtiva === null) {
            this.setState({
                modalSafraOpen: true
            })
        }
    }

    render() {
        const { startSessionReducer } = this.props;

        function renderColumnAtivo(value) {
            return formatCheck(value.status);
        };

        function renderColumnDataInicial(value) {
            return formatDateDDMMYYYY(value.dataInicial);
        };

        function renderColumnDataFinal(value) {
            return formatDateDDMMYYYY(value.dataFinal);
        };

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <i className="fa fa-calendar-check font-size-17" style={{ marginRight: '5px', color: '#175B28' }} />
                        <span style={{ color: '#175B28' }}>{startSessionReducer.configSession == null || startSessionReducer.configSession.safraAtiva == null ?
                            "Nenhuma Safra Selecionada  " : startSessionReducer.configSession.safraAtiva.nome + "  "
                        }</span>
                        <i className="fa fa-angle-down" style={{ color: '#175B28' }} />
                    </DropdownToggle>
                    <DropdownMenu right>
                        {startSessionReducer.configSession == null || startSessionReducer.configSession.safras == null || startSessionReducer.configSession.safras.length <= 1 ?
                            <React.Fragment /> :
                            <React.Fragment>
                                <DropdownItem >
                                    <div onClick={() => this.setState({
                                        modalSafraOpen: true
                                    })}>

                                        {startSessionReducer.configSession == null || startSessionReducer.configSession.safraAtiva == null || startSessionReducer.configSession.safraAtiva == null ?
                                            <i className="fa fa-check font-size-17 align-middle mr-1"></i> : <i className="fa fa-exchange-alt font-size-17 align-middle mr-1"></i>}
                                        {startSessionReducer.configSession == null || startSessionReducer.configSession.safraAtiva == null || startSessionReducer.configSession.safraAtiva == null ?
                                            "Acessar Safra" : "Trocar de Safra"}
                                    </div>
                                </DropdownItem>
                                <div className="dropdown-divider"></div>
                            </React.Fragment>
                        }
                        <DropdownItem tag="a" href="/safraCadastro"><i className="mdi mdi-calendar font-size-17 align-middle mr-1"></i>Gerenciar Safras</DropdownItem>
                    </DropdownMenu>
                </Dropdown>

                {/* MODAL ATIVAR SAFRA */}
                <Modal isOpen={this.state.modalSafraOpen} size="xl">
                    <ModalHeader>Acessar Safra</ModalHeader>
                    <ModalBody>
                        <h4 className="font-size-18">Selecione a safra que você deseja acessar:</h4>

                        <TableNoLazy list={startSessionReducer.configSession.safras}>
                            <Column field='status' header='Ativo' body={renderColumnAtivo} style={{ textAlign: 'center', width: '100px' }} />

                            <Column field='nome' header='Nome' />

                            <Column field='dataInicial' header='Data Inicial' body={renderColumnDataInicial} style={{ textAlign: 'center', width: '150px' }} />

                            <Column field='dataFinal' header='Data Final' body={renderColumnDataFinal} style={{ textAlign: 'center', width: '150px' }} />

                            <Column style={{ textAlign: 'center', width: '20%' }}
                                body={(rowData, column) => {
                                    return (
                                        <div style={{ textAlign: 'center' }} >
                                            <Button onClick={() => this.props.selectSafraFazenda(rowData.id)} >
                                                <i className='fa fa-check' /> Acessar Safra
                                            </Button>
                                        </div>
                                    );
                                }} />
                        </TableNoLazy>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="light" onClick={() => this.setState({
                            modalSafraOpen: false
                        })} >Fechar</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export function mapStateToProps(state) {
    const { startSessionReducer } = state.entities;
    return { startSessionReducer };
};

function mapDispatchToProps(dispatch) {
    return {
        selectSafraFazenda: (id) => dispatch({ type: SELECT_SAFRA_FAZENDA, id })
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SafraMenu))




