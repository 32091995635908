import React, { Component } from "react";
import { Button } from 'reactstrap'
import PropTypes from 'prop-types';

import './style.css'

export default class ButtonLoader extends Component {

    render() {
        const { name, nameLoading, className, type, onClick, loading, icon } = this.props;
        return (
            <Button type={type} className={className} disabled={loading} onClick={onClick}>
                {loading && (
                    <i
                        className="fa fa-circle-notch fa-spin"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {loading && <span className={icon}> {nameLoading}</span>}
                {!loading && <span className={icon}> {name}</span>}
            </Button>
        );
    }
}

ButtonLoader.propTypes = {
    name: PropTypes.string.isRequired,
    nameLoading: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
}