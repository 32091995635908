import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createFuncionarioCargoCadastro, updateFuncionarioCargoCadastro } from '../service';
import {
  CREATE_FUNCIONARIO_CARGO_CADASTRO,
  CREATE_FUNCIONARIO_CARGO_CADASTRO_PENDING,
  CREATE_FUNCIONARIO_CARGO_CADASTRO_SUCCESS,
  CREATE_FUNCIONARIO_CARGO_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateFuncionarioCargoCadastro(action) {
  yield put({ type: CREATE_FUNCIONARIO_CARGO_CADASTRO_PENDING })

  try {
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createFuncionarioCargoCadastro, action.data);
    } else {
      yield call(updateFuncionarioCargoCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_FUNCIONARIO_CARGO_CADASTRO_SUCCESS, registro: action.data })

    history.push('/funcionarioCargoCadastro');

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_FUNCIONARIO_CARGO_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateFuncionarioCargoCadastro() {
  yield takeLatest(CREATE_FUNCIONARIO_CARGO_CADASTRO, sagaCreateFuncionarioCargoCadastro)
}
