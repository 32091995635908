import * as Yup from 'yup';

const newRegistro = {
    descricao: '',
    status: true,
    grupo: null,
    custo: 0,
    dosagemHa: 0,
    unidadeCompra: null,
    unidadeAplicacao: null,
    tipoEmbalagem: null,
    qtdSaca: 0,
    unidadeVenda: null,
    unidadePopulacao: null,
    populacao: null,
};

const validation = Yup.object().shape({
    descricao: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(255, 'Campo deve ter menos do que 255 caracteres')
        .required('Este campo é obrigatório'),
    grupo: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    unidadeCompra: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    unidadeAplicacao: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    custo: Yup.number()
        .typeError('Este campo é obrigatório')
        .required("Este campo é obrigatório"),
    // CAMPO DOSAGEM
    dosagemHa: Yup.number().when('grupo', {
        is: (grupo) => (
            grupo === 'ACARICIDA'
            || grupo === 'ADJUVANTE'
            || grupo === 'ADUBO_FOLIAR'
            || grupo === 'DESSECANTE'
            || grupo === 'FUNGICIDA'
            || grupo === 'HERBICIDA'
            || grupo === 'INSETICIDA'
            || grupo === 'MEDICAMENTO'),
        then: Yup.number()
            .typeError('Este campo é obrigatório')
            .required("Este campo é obrigatório"),
    }),
    // SEMENTE E MUDA
    tipoEmbalagem: Yup.object().when('grupo', {
        is: (grupo) => grupo === 'SEMENTE_E_MUDA',
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    qtdSaca: Yup.number().when('grupo', {
        is: (grupo) => grupo === 'SEMENTE_E_MUDA',
        then: Yup.number()
            .typeError('Este campo é obrigatório')
            .required("Este campo é obrigatório"),
    }),
    unidadeVenda: Yup.object().when('grupo', {
        is: (grupo) => grupo === 'SEMENTE_E_MUDA',
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
});

export const produtoInsumoCadastro = {
    newRegistro,
    validation
}