
export const NEW_PROCEDIMENTO_CADASTRO = 'NEW_PROCEDIMENTO_CADASTRO'
export const NEW_PROCEDIMENTO_CADASTRO_SUCCESS = 'NEW_PROCEDIMENTO_CADASTRO_SUCCESS'

export const CREATE_PROCEDIMENTO_CADASTRO = 'CREATE_PROCEDIMENTO_CADASTRO'
export const CREATE_PROCEDIMENTO_CADASTRO_PENDING = 'CREATE_PROCEDIMENTO_CADASTRO_PENDING'
export const CREATE_PROCEDIMENTO_CADASTRO_SUCCESS = 'CREATE_PROCEDIMENTO_CADASTRO_SUCCESS'
export const CREATE_PROCEDIMENTO_CADASTRO_FAILURE = 'CREATE_PROCEDIMENTO_CADASTRO_FAILURE'

export const TABLE_PROCEDIMENTO_CADASTRO = 'TABLE_PROCEDIMENTO_CADASTRO'
export const TABLE_PROCEDIMENTO_CADASTRO_PENDING = 'TABLE_PROCEDIMENTO_CADASTRO_PENDING'
export const TABLE_PROCEDIMENTO_CADASTRO_SUCCESS = 'TABLE_PROCEDIMENTO_CADASTRO_SUCCESS'
export const TABLE_PROCEDIMENTO_CADASTRO_FAILURE = 'TABLE_PROCEDIMENTO_CADASTRO_FAILURE'

export const LIST_PROCEDIMENTO_CADASTRO = 'LIST_PROCEDIMENTO_CADASTRO'
export const LIST_PROCEDIMENTO_CADASTRO_PENDING = 'LIST_PROCEDIMENTO_CADASTRO_PENDING'
export const LIST_PROCEDIMENTO_CADASTRO_SUCCESS = 'LIST_PROCEDIMENTO_CADASTRO_SUCCESS'
export const LIST_PROCEDIMENTO_CADASTRO_FAILURE = 'LIST_PROCEDIMENTO_CADASTRO_FAILURE'

export const FIND_PROCEDIMENTO_CADASTRO = 'FIND_PROCEDIMENTO_CADASTRO'
export const FIND_PROCEDIMENTO_CADASTRO_PENDING = 'FIND_PROCEDIMENTO_CADASTRO_PENDING'
export const FIND_PROCEDIMENTO_CADASTRO_SUCCESS = 'FIND_PROCEDIMENTO_CADASTRO_SUCCESS'
export const FIND_PROCEDIMENTO_CADASTRO_FAILURE = 'FIND_PROCEDIMENTO_CADASTRO_FAILURE'

export const DELETE_PROCEDIMENTO_CADASTRO = 'DELETE_PROCEDIMENTO_CADASTRO'
export const DELETE_PROCEDIMENTO_CADASTRO_PENDING = 'DELETE_PROCEDIMENTO_CADASTRO_PENDING'
export const DELETE_PROCEDIMENTO_CADASTRO_SUCCESS = 'DELETE_PROCEDIMENTO_CADASTRO_SUCCESS'
export const DELETE_PROCEDIMENTO_CADASTRO_FAILURE = 'DELETE_PROCEDIMENTO_CADASTRO_FAILURE'
