import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createTalhaoCadastro, updateTalhaoCadastro } from '../service';
import {
  CREATE_TALHAO_CADASTRO,
  CREATE_TALHAO_CADASTRO_PENDING,
  CREATE_TALHAO_CADASTRO_SUCCESS,
  CREATE_TALHAO_CADASTRO_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { storage } from '@commons/storage';

import { AlertSuccess, AlertWarning, AlertError } from '@components/common/AlertToast'

function* sagaCreateTalhaoCadastro(action) {
  yield put({ type: CREATE_TALHAO_CADASTRO_PENDING })

  try {
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createTalhaoCadastro, {
        ...action.data,
        propriedade: {
          id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
        }
      });
    } else {
      yield call(updateTalhaoCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_TALHAO_CADASTRO_SUCCESS, registro: action.data })

    history.push('/talhaoCadastro');

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_TALHAO_CADASTRO_FAILURE, registro: action.data })

    if (error.toString().includes('422')) {
      AlertWarning('Área do talhão informada ultrapassa o total liberado para a propriedade. Caso necessite de mais área, contate o suporte.');
    } else {
      AlertError('Falha ao salvar o registro! Tente novamente...');
    }
  }

}

export default function* watchCreateTalhaoCadastro() {
  yield takeLatest(CREATE_TALHAO_CADASTRO, sagaCreateTalhaoCadastro)
}
