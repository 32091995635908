import React from 'react';

import {
    Col,
    Label,
    FormGroup,
    FormFeedback,
} from 'reactstrap';

import { Field, getIn } from 'formik';

import { InfoTooltip } from '@components/form';

export const Check = (props) => {

    const idComponent = props.id === undefined ? 'id_' + props.name : 'id_' + props.id;
    return (
        <Col md={props.col}>
            <FormGroup>
                <label style={{ color: 'transparent' }} >{props.label}</label>
                
                <div className='form-control' style={{ border: 'none' }}>
                    <Field
                        name={props.name}
                        col={props.col}
                        label={props.label}
                        id={idComponent}
                        invalid={getIn(props.errors, props.name) && getIn(props.touched, props.name)}
                        type='checkbox'
                        style={{width: '18px', height: '18px' }}
                    />
                    <Label htmlFor={idComponent} style={{ marginLeft: '5px', fontSize: '15px' }} > {props.label}</Label>
                    {props.showInfo ? <InfoTooltip id={idComponent} title={props.infoTitle} msg={props.infoMsg} /> : <React.Fragment />} 
                </div>
                <FormFeedback>{getIn(props.errors, props.name)}</FormFeedback>
            </FormGroup>
        </Col>
    )

};

Check.defaultProps = {
    col: 12,
    showInfo: false,
    infoTitle: '',
    infoMsg: ''
};