import { takeLatest, put, call } from 'redux-saga/effects';

import { updateConfigAgricultura } from '../service';
import {
  UPDATE_CONFIG_AGRICULTURA,
  UPDATE_CONFIG_AGRICULTURA_PENDING,
  UPDATE_CONFIG_AGRICULTURA_SUCCESS,
  UPDATE_CONFIG_AGRICULTURA_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaUpdateConfigAgricultura(action) {
  yield put({ type: UPDATE_CONFIG_AGRICULTURA_PENDING })

  try {
    yield call(updateConfigAgricultura, action.data)

    yield put({ type: UPDATE_CONFIG_AGRICULTURA_SUCCESS, registro: action.data })

    AlertSuccess('Configuração atualizada com sucesso!');

  } catch (error) {
    yield put({ type: UPDATE_CONFIG_AGRICULTURA_FAILURE, registro: action.data })

    AlertError('Falha ao atualizar configuração! Tente novamente...');
  }

}

export default function* watchUpdateConfigAgricultura() {
  yield takeLatest(UPDATE_CONFIG_AGRICULTURA, sagaUpdateConfigAgricultura)
}
