export const PATRIMONIO_TIPO_ENUM = [
        {
            id: 'BENFEITORIA',
            descricao: "Benfeitoria",
        },
        {
            id: 'ESTACAO_METEOROLOGICA',
            descricao: "Estação Meteorológica",
        },
        {
            id: 'MAQUINA',
            descricao: "Máquina",
        },
        {
            id: 'PIVO',
            descricao: "Pivô",
        },
        {
            id: 'SILO',
            descricao: "Silo",
        },
        {
            id: 'VEICULO',
            descricao: "Veículo",
        },
        {
            id: 'OUTRO',
            descricao: "Outro",
        },
    ];