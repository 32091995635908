import {
    TOTAIS_DASHBOARD_PENDING,
    TOTAIS_DASHBOARD_SUCCESS,
    TOTAIS_DASHBOARD_FAILURE,
    GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_PENDING,
    GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_SUCCESS,
    GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_FAILURE,
    GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_PENDING,
    GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_SUCCESS,
    GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_FAILURE,
    CUSTO_LIST_TABLE_DASHBOARD_PENDING,
    CUSTO_LIST_TABLE_DASHBOARD_SUCCESS,
    CUSTO_LIST_TABLE_DASHBOARD_FAILURE,
} from './actionTypes';

const totaisInit = {
    totalColheita: 0,
    totalSafra: 0,
    totalSafraHa: 0,
    totalInsumos: 0,
    totalFuncionarios: 0,
    totalMaquinarios: 0,
    totalRateioDespDireta: 0
}

const initialState = { totais: totaisInit, loading: false }


function dashboardReducer(state = initialState, action) {

    switch (action.type) {
        case TOTAIS_DASHBOARD_PENDING:
        case GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_PENDING:
        case GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_PENDING:
        case CUSTO_LIST_TABLE_DASHBOARD_PENDING:
            return {
                ...state,
                loading: true,
            }
        case TOTAIS_DASHBOARD_SUCCESS:
            return {
                ...state,
                totais: action.totais,
                // loading: false
            }
        case TOTAIS_DASHBOARD_FAILURE:
            return {
                ...state,
                totais: totaisInit,
                // loading: false
            }
        case GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_SUCCESS:
            return {
                ...state,
                dadosGraficoCustoProducaoPorTalhao: action.dadosGraficoCustoProducaoPorTalhao,
                // loading: false
            }
        case GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_FAILURE:
            return {
                ...state,
                dadosGraficoCustoProducaoPorTalhao: [['', 'Custo', 'Produção']],
                // loading: false
            }
        case GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_SUCCESS:
            return {
                ...state,
                dadosGraficoCustoTipoInsumo: action.dadosGraficoCustoTipoInsumo,
                // loading: false
            }
        case GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_FAILURE:
            return {
                ...state,
                dadosGraficoCustoTipoInsumo: [['Grupo', 'Total']],
                // loading: false
            }
        case CUSTO_LIST_TABLE_DASHBOARD_SUCCESS:
            return {
                ...state,
                dadosListCustosProcedimentos: action.dataList.dadosListCustosProcedimentos,
                dadosListCustosDespesasDireta: action.dataList.dadosListCustosDespesasDireta,
                dadosListCustosInsumos: action.dataList.dadosListCustosInsumos,
                dadosListCustosMaquinarios: action.dataList.dadosListCustosMaquinarios,
                dadosListCustosFuncionarios: action.dataList.dadosListCustosFuncionarios,
                loading: false
            }
        case CUSTO_LIST_TABLE_DASHBOARD_FAILURE:
            return {
                ...state,
                dadosListCustosProcedimentos: [],
                dadosListCustosDespesasDireta: [],
                dadosListCustosInsumos: [],
                dadosListCustosMaquinarios: [],
                dadosListCustosFuncionarios: [],
                loading: false
            }
        default:
            return state
    }
}

export { dashboardReducer };