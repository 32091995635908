import { takeLatest, put, call } from 'redux-saga/effects';

import { findTalhaoCadastro } from '../service';
import {
  FIND_TALHAO_CADASTRO,
  FIND_TALHAO_CADASTRO_PENDING,
  FIND_TALHAO_CADASTRO_SUCCESS,
  FIND_TALHAO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindTalhaoCadastro(action) {
  yield put({ type: FIND_TALHAO_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findTalhaoCadastro, action.id);

    yield put({ type: FIND_TALHAO_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_TALHAO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindTalhaoCadastro() {
  yield takeLatest(FIND_TALHAO_CADASTRO, sagaFindTalhaoCadastro)
}
