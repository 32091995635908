import * as Yup from 'yup';

const newRegistro = {
    data: '',
    obs: '',
    talhao: null,
    procedimento: null,
    custoTotalProdutosInsumos: 0,
    custoTotalMaquinarios: 0,
    custoTotalFuncionarios: 0,
    custoTotalTalhao: 0,
    custoTotalHa: 0,
    utilizaProdutoInsumo: false,
    utilizaMaquinario: false,
    utilizaFuncionario: false,
    aplicacoes: [],
    alocacaoFuncionarios: [],
    utilizacaoMaquinarios: [],
};

const validation = Yup.object().shape({
    data: Yup.date()
        .required('Este campo é obrigatório'),
    talhao: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    procedimento: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Required"),
        }),
});

export const plantioManejo = {
    newRegistro,
    validation
}