import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/funcionario' };
/////////////////////////////////////////////////////////////////////////////////

export function createFuncionarioCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateFuncionarioCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listFuncionarioCadastro(param) {
  return httpService.get({...args, args: param});
}

export function findFuncionarioCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function deleteFuncionarioCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export async function calcCustoHomemHora(payload) {
  return httpService.post({...args, args: '/calculaHomemHora'}, payload);
}
