import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/ativPlantio' };
const argsCustoDireto = { ...path, path: '/custoDireto' };
/////////////////////////////////////////////////////////////////////////////////

export function createPlantioManejo(payload) {
  return httpService.post(args, payload);
}

export function updatePlantioManejo(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listPlantioManejo(param) {
  return httpService.get({...args, args: param});
}

export function findPlantioManejo(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function deletePlantioManejo(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function getCustoTotalSafra(idPropriedade, idSafra) {
  return httpService.get({...args, args: '/custoTotalSafra/' + idPropriedade + '/' + idSafra});
}

export function getCustoTotalTalhao(idPropriedade, idSafra, idTalhao) {
  return httpService.get({...args, args: '/custoTotal/' + idPropriedade + '/' + idSafra + '/' + idTalhao});
}

export function createPlantioManejoCustoDireto(payload) {
  return httpService.post(argsCustoDireto, payload);
}

export function updatePlantioManejoCustoDireto(payload) {
  const { id } = payload

  return httpService.put({ ...argsCustoDireto, args: `/${id}` }, payload);
}

export function deletePlantioManejoCustoDireto(id) {
  return httpService.del({ ...argsCustoDireto, args: `/${id}` });
}