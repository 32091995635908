import { takeLatest, put, call } from 'redux-saga/effects';

import { history, storage } from '@commons';

import { findFazendaCadastro } from '@handler/common/fazendaCadastro/service';
import { findAllSafrasByPropriedade } from '@handler/agricultura/safraCadastro/service';
import {
  CONFIG_SESSION,
  SELECT_FAZENDA,
  SELECT_START_SESSION_PENDING,
  SELECT_START_SESSION_SUCCESS,
  SELECT_START_SESSION_FAILURE
} from '../actionTypes';

function* sagaSelectFazenda(action) {
  yield put({ type: SELECT_START_SESSION_PENDING })

  try {
    const fazendaSelecionada = yield call(findFazendaCadastro, action.id);

    const configSessionAtual = JSON.parse(storage.get(CONFIG_SESSION));

    const safras = yield call(findAllSafrasByPropriedade, fazendaSelecionada.id);
    
    let safraAtiva = null;

    if (safras.content.length === 1) {
      safraAtiva = safras.content[0]
    }

    //////////////////////////////////////////////////////////////////////
    // config session start deve ser atualizada nesse arquivo para qndo possuir uma unica findAllSafrasByPropriedade, e no arquivo ./create que é executado para +1 propriedade
    //////////////////////////////////////////////////////////////////////
    const configSession = {
      ...configSessionAtual,
      fazendaSelecionada,
      safras: safras.content,
      safraAtiva
    }
    
    yield storage.set(CONFIG_SESSION, JSON.stringify(configSession));
    
    yield put({ type: SELECT_START_SESSION_SUCCESS })

    history.push('/painel/agricultura');
  } catch (error) {
    yield put({ type: SELECT_START_SESSION_FAILURE })

  }
}

export default function* watchSelectFazenda() {
  yield takeLatest(SELECT_FAZENDA, sagaSelectFazenda)
}
