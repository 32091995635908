import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import {
    getListCustoProcedimentos,
    getListCustoDespesaDireta,
    getListCustoInsumos,
    getListCustoMaquinarios,
    getListCustoFuncionarios,
} from '../service';
import {
    CONFIG_SESSION,
    CUSTO_LIST_TABLE_DASHBOARD,
    CUSTO_LIST_TABLE_DASHBOARD_PENDING,
    CUSTO_LIST_TABLE_DASHBOARD_SUCCESS,
    CUSTO_LIST_TABLE_DASHBOARD_FAILURE
} from '@handler';
import { formatDateDDMMYYYY } from '@components/common/format';

function* sagaListCustos(action) {
    yield put({ type: CUSTO_LIST_TABLE_DASHBOARD_PENDING })

    try {
        const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada === null ? -1 : JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
        const idSafra = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva === null ? -1 : JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.id;

        const dadosListCustosProcedimentos = yield call(getListCustoProcedimentos,
            idFazenda,
            idSafra,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        const dadosListCustosDespesasDireta = yield call(getListCustoDespesaDireta,
            idFazenda,
            idSafra,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        const dadosListCustosInsumos = yield call(getListCustoInsumos,
            idFazenda,
            idSafra,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        const dadosListCustosMaquinarios = yield call(getListCustoMaquinarios,
            idFazenda,
            idSafra,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        const dadosListCustosFuncionarios = yield call(getListCustoFuncionarios,
            idFazenda,
            idSafra,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        yield put({
            type: CUSTO_LIST_TABLE_DASHBOARD_SUCCESS, dataList: {
                dadosListCustosProcedimentos,
                dadosListCustosDespesasDireta,
                dadosListCustosInsumos,
                dadosListCustosMaquinarios,
                dadosListCustosFuncionarios,
            }
        })

    } catch (error) {
        yield put({ type: CUSTO_LIST_TABLE_DASHBOARD_FAILURE })

    }
}

export default function* watchListCustos() {
    yield takeLatest(CUSTO_LIST_TABLE_DASHBOARD, sagaListCustos)
}
