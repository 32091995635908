


import watchNewPlantioManejo from './saga/new';
import watchNewPlantioManejoCustoDireto from './saga/newCustoDireto';
import watchFindPlantioManejo from './saga/find';
import watchFindPlantioManejoCustoDireto from './saga/findCustoDireto';
import watchCreatePlantioManejo from './saga/create';
import watchCreatePlantioManejoCustoDireto from './saga/createCustoDireto';
import watchListPlantioManejo from './saga/listTable';
import watchDeletePlantioManejo from './saga/delete';
import watchDeletePlantioManejoCustoDireto from './saga/deleteCustoDireto';

export const plantioManejoSaga = [
    watchNewPlantioManejo(),
    watchNewPlantioManejoCustoDireto(),
    watchFindPlantioManejo(),
    watchFindPlantioManejoCustoDireto(),
    watchCreatePlantioManejo(),
    watchCreatePlantioManejoCustoDireto(),
    watchListPlantioManejo(),
    watchDeletePlantioManejo(),
    watchDeletePlantioManejoCustoDireto(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
export * from './entityCustoDireto';
