import { takeLatest, put, call } from 'redux-saga/effects';

import { findColheita } from '../service';
import {
  FIND_COLHEITA,
  FIND_COLHEITA_PENDING,
  FIND_COLHEITA_SUCCESS,
  FIND_COLHEITA_FAILURE
} from '../actionTypes';

function* sagaFindColheita(action) {
  yield put({ type: FIND_COLHEITA_PENDING, id: action.id })

  try {

    const registro = yield call(findColheita, action.id);

    yield put({ type: FIND_COLHEITA_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_COLHEITA_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindColheita() {
  yield takeLatest(FIND_COLHEITA, sagaFindColheita)
}
