import { takeLatest, put, call } from 'redux-saga/effects';

import { history, storage } from '@commons';

import { createPlantioManejoCustoDireto, updatePlantioManejoCustoDireto } from '../service';
import {
  CONFIG_SESSION,
  CREATE_PLANTIO_MANEJO_CUSTO_DIRETO,
  CREATE_PLANTIO_MANEJO_PENDING,
  CREATE_PLANTIO_MANEJO_SUCCESS,
  CREATE_PLANTIO_MANEJO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'

import { formatJsonRemoveEmptyOrNull, isDateValidVerifyDateBetween } from '@components/common/format'

function* sagaCreatePlantioManejoCustoDireto(action) {
  yield put({ type: CREATE_PLANTIO_MANEJO_PENDING })

  try {
    const safraAtiva = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva;
    var dateFrom = safraAtiva.dataInicial;
    var dateTo = safraAtiva.dataFinal;
    var dateCheck = action.data.data;

    if (!isDateValidVerifyDateBetween(dateFrom, dateTo, dateCheck)) {
      yield put({ type: CREATE_PLANTIO_MANEJO_FAILURE, registro: action.data })

      AlertWarning('Data informada fora do período da safra ativa. Verifique...');
    } else {
      if (action.data.talhoes === undefined || action.data.talhoes.length === 0) {
        AlertWarning('Selecione ao menos um talhão');
        yield put({ type: CREATE_PLANTIO_MANEJO_FAILURE, registro: action.data })
      } else {
        let talhoes = []
        action.data.talhoes.forEach(
          (valor) => {
            talhoes = talhoes.concat({
              ativo: true,
              talhao: {
                id: parseInt(valor)
              },
            })
          });

        const registro = {
          data: action.data.data,
          despDireta: action.data.despDireta,
          obs: action.data.obs,
          valor: action.data.valor,
          propriedade: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
          },
          safra: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.id
          },
          custoDiretoTalhao: talhoes
        }

        if (action.data.id === undefined || action.data.id === null) {
          yield call(createPlantioManejoCustoDireto, formatJsonRemoveEmptyOrNull(registro));
        } else {
          yield call(updatePlantioManejoCustoDireto,
            {
              ...registro,
              id: action.data.id
            }
          );
        }

        yield put({ type: CREATE_PLANTIO_MANEJO_SUCCESS, registro: action.data })

        history.push('/plantioManejo');

        AlertSuccess('Registro salvo com sucesso!');
      }
    }
  } catch (error) {
    console.log(error)
    yield put({ type: CREATE_PLANTIO_MANEJO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }
}

export default function* watchCreatePlantioManejoCustoDireto() {
  yield takeLatest(CREATE_PLANTIO_MANEJO_CUSTO_DIRETO, sagaCreatePlantioManejoCustoDireto)
}
