import { takeLatest, put } from 'redux-saga/effects';

import {
  procedimentoCadastro,
  NEW_PROCEDIMENTO_CADASTRO,
  NEW_PROCEDIMENTO_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewProcedimentoCadastro(action) {

  yield put({ type: NEW_PROCEDIMENTO_CADASTRO_SUCCESS, registro: procedimentoCadastro.newRegistro });
}

export default function* watchNewProcedimentoCadastro() {
  yield takeLatest(NEW_PROCEDIMENTO_CADASTRO, sagaNewProcedimentoCadastro)
}
