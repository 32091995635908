import React from 'react';

import {
    Col,
    FormGroup,
    Input,
    Label,
    FormFeedback
} from 'reactstrap';

import { Field } from 'formik';

export const InputDate = (props) => {

    const { col, name, label, placeholder, plusChange } = props;

    return (
        <Col md={col}>

            <FormGroup>
                <Label htmlFor={'id_' + name} >{label}</Label>
                <Field name={name}>
                    {({ field, form, meta }) => (
                        <>
                            <Input
                                {...field}
                                name={name}
                                value={field.value ?? ''}
                                placeholder={placeholder}
                                type='date'
                                id={'id_' + name}
                                invalid={meta.error && meta.touched}
                                autoComplete="off"
                                onChange={(e) => {
                                    form.handleChange(e);
                                    plusChange(e);
                                }}
                            />
                            <FormFeedback>{meta.error}</FormFeedback>
                        </>
                    )}
                </Field>
            </FormGroup>

        </Col>
    )

};

InputDate.defaultProps = {
    col: 12,
    plusChange: () => { }
};