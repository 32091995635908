import { takeLatest, put } from 'redux-saga/effects';

import {
  patrimonioCadastro,
  NEW_PATRIMONIO_CADASTRO,
  NEW_PATRIMONIO_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewPatrimonioCadastro(action) {

  yield put({ type: NEW_PATRIMONIO_CADASTRO_SUCCESS, registro: patrimonioCadastro.newRegistro });
}

export default function* watchNewPatrimonioCadastro() {
  yield takeLatest(NEW_PATRIMONIO_CADASTRO, sagaNewPatrimonioCadastro)
}
