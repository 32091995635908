import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history, storage } from '@commons';

import { deleteSafraCadastro, findAllSafrasByPropriedade } from '../service';
import {
  CONFIG_SESSION,
  DELETE_SAFRA_CADASTRO,
  DELETE_SAFRA_CADASTRO_PENDING,
  DELETE_SAFRA_CADASTRO_SUCCESS,
  DELETE_SAFRA_CADASTRO_FAILURE,
} from '@handler';

import { AlertError } from '@components/common/AlertToast'

function* sagaDeleteSafraCadastro(action) {
  yield put({ type: DELETE_SAFRA_CADASTRO_PENDING, id: action.id })

  try {
    const a = yield call(deleteSafraCadastro, action.id);

    if (a.status !== 204) {
      throw new Error('API delete request failed');
    }

    // ATUALIZA SAFRA NA SESSAO
    const safras = yield call(findAllSafrasByPropriedade, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id);

    const configSessionAtual = JSON.parse(storage.get(CONFIG_SESSION));
    let safraSelecionada = configSessionAtual.safraAtiva;

    if (safras.content.length === 1) {
      safraSelecionada = safras.content[0];
    } else {
      if (configSessionAtual.safraAtiva !== null) {
        if (configSessionAtual.safraAtiva.id === action.id) {
          safraSelecionada = null;
        }
      }
    }

    const configSession = {
      ...configSessionAtual,
      safras: safras.content,
      safraAtiva: safraSelecionada
    }

    yield storage.set(CONFIG_SESSION, JSON.stringify(configSession));

    yield put({ type: DELETE_SAFRA_CADASTRO_SUCCESS, id: action.id });

    swal('Excluído!', '', 'success').then(() => {
      history.go(0)
    });

  } catch (error) {

    yield put({ type: DELETE_SAFRA_CADASTRO_FAILURE });

    AlertError('Falha ao exlcuir o registro! Tente novamente...');
  }
}

export default function* watchDeleteSafraCadastro() {
  yield takeLatest(DELETE_SAFRA_CADASTRO, sagaDeleteSafraCadastro)
}
