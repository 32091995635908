export const NEW_FAZENDA_CADASTRO = 'NEW_FAZENDA_CADASTRO'
export const NEW_FAZENDA_CADASTRO_SUCCESS = 'NEW_FAZENDA_CADASTRO_SUCCESS'

export const CREATE_FAZENDA_CADASTRO = 'CREATE_FAZENDA_CADASTRO'
export const CREATE_FAZENDA_CADASTRO_PENDING = 'CREATE_FAZENDA_CADASTRO_PENDING'
export const CREATE_FAZENDA_CADASTRO_SUCCESS = 'CREATE_FAZENDA_CADASTRO_SUCCESS'
export const CREATE_FAZENDA_CADASTRO_FAILURE = 'CREATE_FAZENDA_CADASTRO_FAILURE'

export const TABLE_FAZENDA_CADASTRO = 'TABLE_FAZENDA_CADASTRO'
export const TABLE_FAZENDA_CADASTRO_PENDING = 'TABLE_FAZENDA_CADASTRO_PENDING'
export const TABLE_FAZENDA_CADASTRO_SUCCESS = 'TABLE_FAZENDA_CADASTRO_SUCCESS'
export const TABLE_FAZENDA_CADASTRO_FAILURE = 'TABLE_FAZENDA_CADASTRO_FAILURE'

export const LIST_FAZENDA_CADASTRO = 'LIST_FAZENDA_CADASTRO'
export const LIST_FAZENDA_CADASTRO_PENDING = 'LIST_FAZENDA_CADASTRO_PENDING'
export const LIST_FAZENDA_CADASTRO_SUCCESS = 'LIST_FAZENDA_CADASTRO_SUCCESS'
export const LIST_FAZENDA_CADASTRO_FAILURE = 'LIST_FAZENDA_CADASTRO_FAILURE'

export const FIND_FAZENDA_CADASTRO = 'FIND_FAZENDA_CADASTRO'
export const FIND_FAZENDA_CADASTRO_PENDING = 'FIND_FAZENDA_CADASTRO_PENDING'
export const FIND_FAZENDA_CADASTRO_SUCCESS = 'FIND_FAZENDA_CADASTRO_SUCCESS'
export const FIND_FAZENDA_CADASTRO_FAILURE = 'FIND_FAZENDA_CADASTRO_FAILURE'

export const DELETE_FAZENDA_CADASTRO = 'DELETE_FAZENDA_CADASTRO'
export const DELETE_FAZENDA_CADASTRO_PENDING = 'DELETE_FAZENDA_CADASTRO_PENDING'
export const DELETE_FAZENDA_CADASTRO_SUCCESS = 'DELETE_FAZENDA_CADASTRO_SUCCESS'
export const DELETE_FAZENDA_CADASTRO_FAILURE = 'DELETE_FAZENDA_CADASTRO_FAILURE'
