import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createProcedimentoCadastro, updateProcedimentoCadastro } from '../service';
import {
  CREATE_PROCEDIMENTO_CADASTRO,
  CREATE_PROCEDIMENTO_CADASTRO_PENDING,
  CREATE_PROCEDIMENTO_CADASTRO_SUCCESS,
  CREATE_PROCEDIMENTO_CADASTRO_FAILURE
} from '@handler';

import { AlertSuccess, AlertWarning, AlertError } from '@components/common/AlertToast'

function* sagaCreateProcedimentoCadastro(action) {
  yield put({ type: CREATE_PROCEDIMENTO_CADASTRO_PENDING })

  try {
    if (!action.data.utilizaProdutoInsumo && !action.data.utilizaMaquinario && !action.data.utilizaFuncionario) {
      AlertWarning('Selecione ao menos um controle para lançar no plantio e manejo');
      yield put({ type: CREATE_PROCEDIMENTO_CADASTRO_FAILURE, registro: action.data })
    } else {
      if (action.data.id === undefined || action.data.id === null) {
        yield call(createProcedimentoCadastro, action.data);
      } else {
        yield call(updateProcedimentoCadastro,
          {
            ...action.data,
            id: action.data.id
          }
        );
      }

      yield put({ type: CREATE_PROCEDIMENTO_CADASTRO_SUCCESS, registro: action.data })

      history.push('/procedimentoCadastro');

      AlertSuccess('Registro salvo com sucesso!');
    }
  } catch (error) {
    yield put({ type: CREATE_PROCEDIMENTO_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateProcedimentoCadastro() {
  yield takeLatest(CREATE_PROCEDIMENTO_CADASTRO, sagaCreateProcedimentoCadastro)
}
