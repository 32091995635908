import React from 'react';

import {
    Col,
    Label,
} from 'reactstrap';


export const OutputText = (props) => {

    return (
        <Col md={props.col}>

            <Label>{props.label}</Label>
            <p className='form-control' style={{backgroundColor: '#e9ecef', fontWeight: 'bold'}}>{props.value}</p>

        </Col>
    )

};

OutputText.defaultProps = {
    col: 12,
};