import React from 'react';

import {
    Col,
    FormGroup,
    Label,
    FormFeedback
} from 'reactstrap';
import TimeInput from 'react-input-time';

import { Field } from 'formik';

export const InputTime = (props) => {

    const { col, name, label, placeholder, plusChange } = props;

    return (
        <Col md={col}>

            <FormGroup>
                <Label htmlFor={'id_' + name} >{label}</Label>
                <Field name={name}>
                    {({ field, form, meta }) => (
                        <>
                            <TimeInput
                                {...field}
                                name={name}
                                initialTime={field.value ?? "00:00"}
                                placeholder={placeholder}
                                className='form-control'
                                id={'id_' + name}
                                invalid={meta.error && meta.touched}
                                autoComplete="off"
                                onChange={(e) => {
                                    form.handleChange(e);
                                    plusChange(e);
                                }}
                            />
                            <FormFeedback>{meta.error}</FormFeedback>
                        </>
                    )}
                </Field>
            </FormGroup>

        </Col>
    )

};

InputTime.defaultProps = {
    col: 12,
    plusChange: () => { }
};