import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import { getTotais } from '../service';
import {
    CONFIG_SESSION,
    TOTAIS_DASHBOARD,
    TOTAIS_DASHBOARD_PENDING,
    TOTAIS_DASHBOARD_SUCCESS,
    TOTAIS_DASHBOARD_FAILURE
} from '@handler';
import { formatDateDDMMYYYY } from '@components/common/format';

function* sagaTotais(action) {
    yield put({ type: TOTAIS_DASHBOARD_PENDING })

    try {
        const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada === null ? -1 : JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
        const idSafra = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva === null ? -1 : JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.id;

        const totais = yield call(getTotais,
            idFazenda,
            idSafra,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        yield put({ type: TOTAIS_DASHBOARD_SUCCESS, totais: totais })

    } catch (error) {
        yield put({ type: TOTAIS_DASHBOARD_FAILURE })

    }
}

export default function* watchTotais() {
    yield takeLatest(TOTAIS_DASHBOARD, sagaTotais)
}
