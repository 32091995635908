import { takeLatest, put, call } from 'redux-saga/effects';

import { storage } from '@commons';

import { findSafraCadastro } from '@handler/agricultura/safraCadastro/service';
import {
  CONFIG_SESSION,
  SELECT_SAFRA_FAZENDA,
  SELECT_START_SESSION_PENDING,
  SELECT_START_SESSION_SUCCESS,
  SELECT_START_SESSION_FAILURE
} from '../actionTypes';

function* sagaSelectSafraFazenda(action) {
  yield put({ type: SELECT_START_SESSION_PENDING })

  try {
    const safraSelecionada = yield call(findSafraCadastro, action.id);

    const configSessionAtual = JSON.parse(storage.get(CONFIG_SESSION));

    const configSession = {
      ...configSessionAtual,
      safraAtiva: safraSelecionada
    }
    
    yield storage.set(CONFIG_SESSION, JSON.stringify(configSession));
    
    yield put({ type: SELECT_START_SESSION_SUCCESS })

    window.location.reload();
  } catch (error) {
    yield put({ type: SELECT_START_SESSION_FAILURE })

  }
}

export default function* watchSelectSafraFazenda() {
  yield takeLatest(SELECT_SAFRA_FAZENDA, sagaSelectSafraFazenda)
}
