import { takeLatest, put, call } from 'redux-saga/effects';

import { findDespesaDiretaCadastro } from '../service';
import {
  FIND_DESPESA_DIRETA_CADASTRO,
  FIND_DESPESA_DIRETA_CADASTRO_PENDING,
  FIND_DESPESA_DIRETA_CADASTRO_SUCCESS,
  FIND_DESPESA_DIRETA_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindDespesaDiretaCadastro(action) {
  yield put({ type: FIND_DESPESA_DIRETA_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findDespesaDiretaCadastro, action.id);

    yield put({ type: FIND_DESPESA_DIRETA_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_DESPESA_DIRETA_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindDespesaDiretaCadastro() {
  yield takeLatest(FIND_DESPESA_DIRETA_CADASTRO, sagaFindDespesaDiretaCadastro)
}
